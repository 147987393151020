import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { customFunctions } from "@/config/customFunction";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { setWmsSatus, wmsStatusColor } from "@/utils/wmsStatus";
import { setWorkStatus, trackingStatusColor } from "@/utils/workStatus";
import { Badge } from "@mantine/core";
import { ItemsGet200ResponseRowsInner, LedgersGet200ResponseRowsInner, LocationsGet200ResponseRowsInner, WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface LotLedgeTabsProps {
    lotId: number | undefined;
}

interface Additional {
    purchaseItemId: number;
    wmsAction: string;
    workAction: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum | undefined;
    unit_text: string;
}

export const LotLedgeTabs = (params: LotLedgeTabsProps) => {
    const { lotId } = params;

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });

    // 각 컬럼 filter
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
    const [globalFilter, setGlobalFilter] = useState('');
    // 각 컬럼 sort
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
    const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{ [key: string]: { $iLike: string } }>((acc, filter) => {
        acc[filter.id] = { $iLike: `%${filter.value}%` as string };
        return acc;
    }, {});

    // sort를 id 별 string으로 만들어줌
    const sortString = sorting
        .map(({ id, desc }) => (desc ? `-${id}` : id))
        .join(',');

    const { data } = useQuery(ledgers.get({
        query: {
            $and: [
                { lotId: { $eq: lotId } },
                ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] }))
            ]
        },
        populate: ["item", "location", "user"],
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
        search: globalFilter,
        searchFields: ["itemCode", "locationCode"],
        sort: sortString || undefined
    }))

    const rows: LedgersGet200ResponseRowsInner[] = data?.data?.rows ?? [];
    const { openModal } = useModal();
    const { t } = useTranslation();

    const columns = useMemo<MRT_ColumnDef<LedgersGet200ResponseRowsInner>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '번호',
                size: 80,
                enableColumnFilter: false,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            ...(customFunctions.ADD_VISIBLE_ITEM_CODE ? [
                {
                    accessorFn: (row: any) =>
                        <DetailLink
                            onClick={() => {
                                openModal(
                                    <ItemsDetailForm itemCode={row.itemCode} />,
                                    null,
                                    "품목 상세"
                                )
                            }}
                            justify="flex-start"
                        >
                            {(row.itemData as ItemsGet200ResponseRowsInner)?.name}
                        </DetailLink>,
                    accessorKey: 'itemCode',
                    header: '품목코드',
                    size: 80,
                },
            ] : []),
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row.locationCode} />,
                                null,
                                ""
                            )
                        }}
                        justify="flex-start"
                    >
                        {(row.locationData as LocationsGet200ResponseRowsInner)?.name}
                    </DetailLink>,
                accessorKey: 'locationCode',
                header: '위치',
                size: 80,
            },
            {
                accessorFn: (row) =>
                    (row.additional as Additional)?.wmsAction ?
                        <Badge color={wmsStatusColor[(row.additional as Additional)?.wmsAction as keyof typeof wmsStatusColor]}>
                            {setWmsSatus((row.additional as Additional)?.wmsAction)}
                        </Badge>
                        :
                        (row.additional as Additional)?.workAction ?
                            <Badge color={trackingStatusColor[(row.additional as Additional)?.workAction as keyof typeof trackingStatusColor]}>
                                {setWorkStatus((row.additional as Additional)?.workAction)}
                            </Badge>
                            :
                            ''
                ,
                accessorKey: 'wmsAction',
                header: '상세',
                size: 80,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => `${row.quantity} ${(row.itemData as Additional)?.unit_text}`,
                accessorKey: 'quantity',
                header: '재고 변화량',
                size: 80,
                enableColumnFilter: false,
                Cell: ({ row }) => <div style={{ textAlign: 'right' }}>{row._valuesCache.quantity}</div>,
            },
            {
                accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                accessorKey: 'createdAt',
                header: '생성일',
                size: 200,
                enableColumnFilter: false,
            }
        ],
        [],
    );

    return (
        <>
            <MantineReactCommonTable
                data={rows as LedgersGet200ResponseRowsInner[]}
                columns={columns}
                totalCount={data?.data.total}
                totalPages={data?.data.totalPages}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                sorting={sorting}
                setSorting={setSorting}
                enableTopToolbar={true}
            />
        </>
    );
}