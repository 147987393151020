import { mutateStockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { WmsApiStockPlansPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

export const StockPlanCreateForm = () => {
    const { closeModal } = useModal();
    const [itemCode, setItemCode] = useState<string | undefined>(undefined);
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: WmsApiStockPlansPostRequest) =>
            mutateStockPlans.create(params).mutationFn(params as WmsApiStockPlansPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stockPlans']);
                closeModal(form.values);
                customAlert(
                    "재고 계획 등록에 성공하였습니다.",
                    "등록 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                customAlert(handleErrorResponse({ error, errorContext: '재고 계획' }), '재고 계획 등록 실패', 'red')
            }
        }
    );

    const form = useForm({
        initialValues: {
            partnerCode: undefined,
            scheduledAt: undefined,
            itemCode: undefined,
            lotId: undefined,
            quantity: undefined,
            userCode: undefined,
            sourceLocationCode: undefined,
            targetLocationCode: undefined,
            transportationId: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    stockPlansGetRequest: {
                        partnerCode: form.values.partnerCode!,
                        direction: "INBOUND",
                        scheduledAt: form.values.scheduledAt!,
                        itemCode: form.values.itemCode!,
                        lotId: form.values.lotId!,
                        quantity: form.values.quantity!,
                        userCode: form.values.userCode!,
                        sourceLocationCode: form.values.sourceLocationCode!,
                        targetLocationCode: form.values.targetLocationCode!,
                        transportationId: form.values.transportationId!,
                    }
                },
            )
        } catch (error) {
            customAlert(handleErrorResponse({ error, errorContext: '재고 계획' }), '재고 계획 등록 실패', 'red')
        }
    };

    return (
        <>
            <Create.Wrapper>
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="예정일자"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("scheduledAt")}
                />
                <ItemAutoComplete
                    label="품목"
                    placeholder="품목 입력"
                    {...form.getInputProps("itemCode")}
                />
                <LotSelectComplete
                    label="로트"
                    placeholder="로트 입력"
                    itemCodeValue={form.values.itemCode ?? ''}
                    {...form.getInputProps("lotId")}
                />
                <Create.Input
                    label="수량"
                    placeholder="수량 입력"
                    {...form.getInputProps("quantity")}
                />
                <UserAutoComplete
                    label="담당자"
                    placeholder="담당자 입력"
                    {...form.getInputProps("userCode")}
                />
                <LocationAutoComplete
                    label="출고 로케이션"
                    placeholder="출고 로케이션"
                    {...form.getInputProps("sourceLocationCode")}
                />
                <LocationAutoComplete
                    label="입고 로케이션"
                    placeholder="입고 로케이션"
                    {...form.getInputProps("targetLocationCode")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "stockPlan",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        재고계획 생성
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};