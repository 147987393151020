import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";

import { consumeType, routingType } from "@/constants/routings";
import { useModal } from "@/features/modal/ModalStackManager";
// import i18n from "@/language/i18n";
import { mutateRoutings } from "@/api/routing/useRoutingQuery";
import { customFunctions } from "@/config/customFunction";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingsPostRequest, ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface RoutingCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const RoutingCreateForm = (params: RoutingCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { t } = useTranslation();
  const lnRoutingType = routingType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingsPostRequest) =>
      mutateRoutings.create(params).mutationFn(params as DefaultApiRoutingsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['routings']);
        closeModal(form.values);
              customAlert(
                "라우팅 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '라우팅코드' }), '라우팅 생성 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      itemCode: undefined,
      code: code ?? undefined,
      routingType: undefined,
      moldCode: undefined,
      consumeType: undefined,
      operationCode: undefined,
      seq: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          routingsGetRequest: {
            itemCode: form.values.itemCode as any,
            code: code ?? form.values.code as any,
            routingType: form.values.routingType as any,
            moldCode: form.values.moldCode as any,
            consumeType: form.values.consumeType as any,
            operationCode: form.values.operationCode as any,
            seq: form.values.seq as any,
          }
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '라우팅코드' }), '라우팅 생성 실패', 'red')
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <ItemAutoComplete
            withAsterisk
            label={!customFunctions.ADD_VISIBLE_ITEM_CODE ? "품목코드" : "품목"}
            {...form.getInputProps("itemCode")}
            getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT]}
          />
          <OperationAutoComplete
            withAsterisk
            label="공정코드"
            {...form.getInputProps("operationCode")}
          />
          <TextInput
            withAsterisk
            label="라우팅코드"
            {...form.getInputProps("code")}
          />
          <Select
            withAsterisk
            label="라우팅유형"
            data={lnRoutingType}
            {...form.getInputProps("routingType")}
          />
          <MoldAutoComplete
            label="금형코드"
            {...form.getInputProps("moldCode")}
          />
          <Select
            withAsterisk
            label="소모 시점"
            data={lnConsumeType}
            {...form.getInputProps("consumeType")}
          />
          <TextInput
            withAsterisk
            label="라우팅순서"
            {...form.getInputProps("seq")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routing",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              라우팅 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
