import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { BomByOperationCreateForm } from "@/features/bomSearch/table/form/BomByOperationCreateForm";
import { BomByOperationUpdateForm } from "@/features/bomSearch/table/form/BomByOperationUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Table, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DefaultApiRoutingBomsRoutingBomIdDeleteRequest, ItemsGet200ResponseRowsInnerRoutingsInner, ItemsGet200ResponseRowsInnerRoutingsInnerRoutingBomsInner, ItemsOneStepCreatePostRequestRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ItemsDetailForm } from "../ItemsDetailForm";

interface RoutingBomRowProps<T> {
    bomRow: T extends 'PREVIEW'
    ? ItemsOneStepCreatePostRequestRoutingsInner
    : ItemsGet200ResponseRowsInnerRoutingsInner
    rowIndex: number
}


export const RoutingBomRow = <T,>({ bomRow, rowIndex }: RoutingBomRowProps<T>) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure(false);
    const { openModal } = useModal();

    const queryClient = useQueryClient();

    const isPreview = (bomRow as ItemsGet200ResponseRowsInnerRoutingsInner)?.id === undefined;

    const { mutate: deleteMutate } = useMutation(
        (params: DefaultApiRoutingBomsRoutingBomIdDeleteRequest) =>
            mutateRoutingBoms.delete(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["items"]);
                customAlert('삭제되었습니다.', '성공', 'green');
            },
            onError: (error: AxiosError<Error>) => {
                customAlert(
                    error.response?.data?.message ?? "Bom 삭제에 실패하였습니다.",
                    "등록 실패",
                    "red"
                )
            }
        }
    );

    const openEditModal = ({ routingBom }: { routingBom: ItemsGet200ResponseRowsInnerRoutingsInnerRoutingBomsInner }) => {
        openModal(
            <BomByOperationUpdateForm data={routingBom} />,
            null,
            "BOM 수정"
        );
    }

    const openCreateModal = ({ routingCode }: { routingCode: string | undefined }) => {
        openModal(
            <BomByOperationCreateForm routingCode={routingCode} />,
            null,
            "BOM 추가"
        );
    };

    const handleDeleteBom = ({ routingBomId }: { routingBomId: number | undefined }) => {
        if (routingBomId === undefined) return;
        deleteMutate({
            routingBomId: routingBomId
        })
    }

    return (
        <Fragment>
            <tr key={bomRow.code ?? "" + rowIndex}>
                <td>
                    <Flex justify="center" align="center">
                        {bomRow.routingBoms && bomRow.routingBoms.length && !opened ? (<IconPlus onClick={toggle} />) : (<IconMinus onClick={toggle} />)}
                    </Flex>
                </td>
                <td><Text ta="end">{bomRow.seq}</Text></td>
                <td>{bomRow.operationCode}</td>
                <td>{bomRow.code}</td>
                <td><Badge color={routingTypeColor[bomRow.routingType!]}>{t(bomRow.routingType ?? "")}</Badge></td>
                <td><Badge color={consumeTypeColor[bomRow.consumeType!]}>{t(bomRow.consumeType ?? "")}</Badge></td>
            </tr>
            {
                bomRow.routingBoms && opened && (
                    <tr style={{ backgroundColor: "#f3f0ff" }}>
                        <td colSpan={3}></td>
                        <td colSpan={3}>
                            <Table withColumnBorders>
                                <thead>
                                    <tr>
                                        <Th width={60}>원부자재 품목코드</Th>
                                        <Th width={20}>수량</Th>
                                        {
                                            !isPreview && <Th width={20}>액션</Th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bomRow.routingBoms &&
                                        bomRow.routingBoms.map((bom, i) => {
                                            return (
                                                <tr key={bom.itemCode ?? "" + i}>
                                                    <td>
                                                        <Flex justify="flex-start">
                                                            <DetailLink onClick={() => openModal(
                                                                <ItemsDetailForm itemCode={bom.itemCode as string} />,
                                                                null,
                                                                "품목 상세"
                                                            )}>
                                                                {bom.itemCode}
                                                            </DetailLink>
                                                        </Flex>
                                                    </td>
                                                    <td>{bom.quantity}</td>
                                                    {
                                                        !isPreview &&
                                                        <td width={20} >
                                                            <Button
                                                                w={32}
                                                                variant="subtle"
                                                                onClick={() => openEditModal({ routingBom: bom })}
                                                                leftIcon={<IconEdit />}
                                                                color="yellow"
                                                            ></Button>
                                                            <Button
                                                                w={32}
                                                                variant="subtle"
                                                                onClick={() => handleDeleteBom({
                                                                    routingBomId:
                                                                        (bom as ItemsGet200ResponseRowsInnerRoutingsInnerRoutingBomsInner).id
                                                                })}
                                                                leftIcon={<IconTrash />}
                                                                color="red"
                                                            ></Button>
                                                        </td>
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                    {
                                        !isPreview &&
                                        <tr>
                                            <td colSpan={3} style={{ textAlign: 'center' }}>
                                                <Button
                                                    leftIcon={<IconPlus />}
                                                    variant={'subtle'}
                                                    onClick={() => openCreateModal({ routingCode: bomRow.code })}
                                                >
                                                    새로운 BOM 추가
                                                </Button>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                )
            }
        </Fragment >
    )
}

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;