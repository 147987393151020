import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemCategoriesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface ItemCategoryCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const ItemCategoryCreateForm = (params: ItemCategoryCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiItemCategoriesPostRequest) =>
      mutateItemCategories.create(params).mutationFn(params as MasterApiItemCategoriesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['itemCategories']);
        closeModal(form.values);
        customAlert(
          "품목분류 생성에 성공하였습니다.",
          "생성에 성공",
          "green"
        )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '품목분류' }), '품목분류 생성 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      name: name ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          itemCategoriesGetRequest: {
            name: name ?? form.values.name!,
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '품목분류' }), '품목분류 생성 실패', 'red')
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        label="품목분류"
        withAsterisk
        {...form.getInputProps("name")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          disabled={validateFormValues({
            pageType: "itemCategory",
            values: form.values,
          })}
        >
          품목분류 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
