import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FileImportForm } from "@/features/ui/form/file";
import { BASE_URL } from "@/instance/axios";
import { getUserCodeByUser } from "@/utils/checkData";
import { Flex, Menu, ScrollArea, Text } from "@mantine/core";
import { useResizeObserver } from "@mantine/hooks";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

type LinkType = {
    label: string;
    links?: (Link | NestedLink)[];
    link?: string;
    visible?: boolean; // 추가
    target?: string;
}[];

type Link = {
    link: string;
    label: string;
    action?: boolean;
    actionKey?: 'download' | 'upload';
    visible?: boolean; // 추가
    target?: string;
};

type NestedLink = {
    label: string;
    links: (Link | NestedLink)[];
    action?: boolean;
    actionKey?: 'download' | 'upload';
    visible?: boolean; // 추가
    target?: string;
};

// 메뉴 Item의 최대 높이 설정. 높이가 MAX_HEIGHT보다 크면 스크롤바를 표시하기 위함
const MAX_HEIGHT = 400; // 25rem

const links: LinkType = [
    { link: "/dashboard", label: "대시보드", visible: true },
    {
        label: "테스트",
        visible: customFunctions.ADD_BEST_PRACTICE_TEST_MENU_SETTING,
        links: [
            { link: "/test-standard-info-best-practice", label: "기준정보", visible: true },
        ]
    },
    {
        label: '시스템관리',
        visible: customFunctions.ADD_SYSTEM_MANAGEMENT_MENU_SETTING,
        links: [
            { link: '/system', label: 'System Access Log', visible: true },
            { link: '/system-logs', label: '로그정보 API 연동', visible: true },
            { link: '/system-parameter', label: '시스템파라미터 관리', visible: true },
        ]
    },
    {
        label: "기준정보",
        visible: true,
        links: [
            { link: "/common-code", label: "공통코드", visible: customFunctions.ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING },
            { link: "/authority", label: "권한그룹", visible: customFunctions.ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING },
            { link: "/department", label: "부서관리", visible: customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING },
            { link: "/items", label: "품목", visible: true },
            { link: "/equipment", label: "설비", visible: true },
            { link: "/defects", label: "불량", visible: true },
            { link: "/operations", label: "공정", visible: true },
            { link: "/routing", label: "라우팅", visible: true },
            { link: "/users", label: "사용자", visible: true },
            { link: "/userLogs", label: "사용자로그", visible: customFunctions.ADD_USER_ACCESS_STATUS },
            { link: "/site", label: "사업장", visible: true },
            { link: "/location", label: "로케이션", visible: true },
            { link: "/routing-outsource", label: "외주처", visible: true },
            { link: "/ALCmodel", label: "ALC 모델코드", visible: customFunctions.ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING },
            { link: "/mold", label: "금형", visible: true },
            { link: "/moldStock", label: "금형재고", visible: true },
            { link: "/abstract-item", label: "품목군", visible: true },
            { link: "/item-category", label: "품목분류", visible: true },
            { link: "/partner", label: "협력사", visible: customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING },
            { link: "/downtimeReasons", label: "비가동사유(일시정지)", visible: true },
            { link: "/bom", label: "BOM등록(배합률)", visible: customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING },
            { link: "/bomSearch", label: "BOM조회", visible: customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING },
            { link: "/bomList", label: "BOM전개조회", visible: customFunctions.ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING },
            { link: "/document-file", label: "문서관리(파일첨부)", visible: customFunctions.ADD_STANDARD_INFO_FILE_MENU_SETTING },
            { link: "/peripheral", label: "주변장치", visible: customFunctions.ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING },
            { link: "/machine", label: "기계", visible: customFunctions.ADD_STANDARD_INFO_MACHINE_MENU_SETTING },
            { link: "/grade", label: "등급", visible: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING },
            { link: "/#", label: "기준정보 다운로드", action: true, actionKey: 'download', visible: true },
            { link: "/#", label: "기준정보 업로드", action: true, actionKey: 'upload', visible: true },
        ]
    },
    {
        label: "생산관리",
        visible: true,
        links: [
            { link: "/work-by-equipment", label: "설비별 생산현황", visible: true },
            { link: "/work-by-production-plan", label: "생산계획별 생산현황", visible: true },
            { link: "/work-by-row", label: "작업지시", visible: true },
            { link: "/operation-outsource-work-by-row", label: "외주 작업지시", visible: customFunctions.ADD_SHOW_OPERATION_OUTSOURCING_WORK },
            { link: "/work-log", label: "작업일지", visible: true },
            { link: "/productionpreformance", label: "생산실적조회", visible: customFunctions.ADD_PERFORMANCE_MENU_SETTING },
            { link: "/productionperformanceOutsourcing", label: "외주실적조회", visible: customFunctions.ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING },
            // { link: "/downtimeReasons-log", label: "정지유형별 통계", visible: customFunctions.ADD_DOWNTIME_REASON_LOG_MENU_SETTING },
        ],
    },
    {
        label: "재고관리",
        visible: true,
        links: [
            { link: "/inventory", label: "재고현황", visible: true },
            { link: "/ledgersAll", label: "전체 입출고 내역", visible: true },
            { link: "/wipInventory", label: "재공품 재고", visible: true },
            { link: "/wmsLogs", label: "WMS 로그", visible: true },
            { link: "/lackSafety", label: "안전재고부족조회", visible: customFunctions.ADD_LACK_SAFETY_MENU_SETTING },
            { link: "/inventoryLife", label: "재고수명조회", visible: customFunctions.ADD_INVENTORY_LIFE_MENU_SETTING },
        ],
    },
    {
        label: "입출고관리",
        visible: customFunctions.ADD_STORAGE_MANAGEMENT_MENU_SETTING,
        links: [
            { link: "/shipment-request", label: "입고의뢰", visible: true },
            { link: "/shipment-receipt", label: "입고등록", visible: true },
            { link: "/shipment-status", label: "입고현황", visible: true },
        ]
    },
    {
        label: "영업관리",
        visible: true,
        links: [
            { link: "/purchaseOrder", label: "수주현황", visible: true },
            { link: "/purchaseOrderItems", label: "수주항목", visible: customFunctions.ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING },
            { link: "/deliveryStatus", label: "납품현황", visible: true },
            { link: "/order", label: "발주현황", visible: true },
            { link: "/orderItems", label: "발주항목", visible: true },
            { link: "/orderComplete", label: "발주완료항목", visible: customFunctions.ADD_PURCHASE_ORDER_COMPLETE_MENU_SETTING },
            { link: "/stockPlan", label: "재고계획", visible: true },
            { link: "/transportation", label: "배차", visible: true },
        ]
    },
    {
        label: "품질관리",
        visible: customFunctions.ADD_QUALITY_MENU_SETTING,
        links: [
            { link: "/inspectSpecification", label: "검사기준", visible: true },
            { link: "/inspectionRecords", label: "검사기록", visible: true },
            { link: "/quality/defect-statistics", label: "불량통계(자주검사)", visible: customFunctions.ADD_DEFECT_STATISTICS_MENU_SETTING },
            { link: "/quality/work-standardList", label: "작업표준서 관리", visible: customFunctions.ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING },
            // { link: "/quality/change-register", label: "변경점 정보 등록" },
            { link: "/quality/change-list", label: "변경점 정보 관리", visible: customFunctions.ADD_CHANGE_LIST_MENU_SETTING },
            // { link: "/quality/pre-warehouseList", label: "수입검사", visible: customFunctions.ADD_PRE_WAREHOUSE_LIST_MENU_SETTING },
        ]
    },
    {
        label: "KPI",
        visible: customFunctions.ADD_KPI_MENU_SETTING,
        links: [
            {
                link: "/kpi/leadtime",
                label: "제조리드타임(P)",
                visible: customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING
            },
            {
                link: "/kpi/manhour",
                label: "작업공수(C)",
                visible: customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING
            },
            {
                link: "/kpi/defect",
                label: "품질 불량률(O)",
                visible: customFunctions.ADD_KPI_MENU_SETTING
            },
            {
                link: "/kpi/leadtimeOrder",
                label: "수주/납품 리드타임(D)",
                visible: customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING
            },
            {
                link: "/kpi/powerUsage",
                label: "전력사용량(E)",
                visible: customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING
            },
            {
                link: "/kpi/uph",
                label: "UPH(P)",
                visible: customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING
            },
            {
                link: "/kpi/rateOfOperation",
                label: "설비가동률(P)",
                visible: customFunctions.ADD_KPI_MENU_SETTING
            },
            {
                link: '/dataDisplay',
                label: '데이터 표출',
                visible: customFunctions.ADD_DATA_DISPLAY_MENU_SETTING
            }
        ]
    },
    {
        label: "현황 모니터링",
        visible: customFunctions.ADD_MONITORING_MENU_SETTING,
        links: [
            { link: "/overview", label: "MES 운영현황판", visible: true },
            { link: "/monitoring", label: "현황 모니터링", visible: true },
            { link: "/equipmentAnalysis", label: "설비별 통계성 데이터", visible: customFunctions.ADD_DASHBOARD_EQUIPMENT_ANALYSIS },
            { link: '/pressMonitoring/factoryMonitoring', label: 'PMS 분석 모니터링', visible: customFunctions.ADD_PMS_PRESS_MONITORING },
        ]
    },
    {
        label: "PMS",
        visible: customFunctions.ADD_PMS_MENU_SETTING,
        links: [
            {
                link: "/monitoring", label: "설비 모니터링", visible: true
            },
            {
                link: "/pressMonitoring/factoryMonitoring", label: "고속성형기별 분석 모니터링", visible: true
            },
            {
                link: "/press", label: "고속성형기 관리", links: [
                    { link: '/press/errorview', label: '에러보기', visible: true },
                    // { link: '/press/parameterview', label: '파라미터 보기', visible: true },
                    // { link: '/press/camview', label: '캠 보기', visible: true },
                    // { link: '/press/clutchandbrake', label: '클러치 & 브레이크', visible: true },
                    { link: '/press/facilities', label: '설비 수리 요청', visible: true },
                    { link: '/press/complete/admin', label: '설비 수리 요청 완료(관리자)', visible: true },
                    { link: '/press/problem', label: '설비 문제 유형 등록', visible: true },
                    { link: '/press/PressDailyRegister', label: '고속성형기 일상점검 등록', visible: true },
                    { link: '/press/PressDailyStatus', label: '고속성형기 일상점검 일일현황', visible: true },
                ],
                visible: true
            },
            {
                link: "/analysis", label: "고속성형기 데이터 통계 및 분석", links: [
                    { link: '/analysis/output', label: '생산량', visible: true },
                    { link: '/analysis/ability', label: '능력', visible: true },
                    // { link: '/analysis/totaloutput', label: '총생산량', visible: true },
                    { link: '/analysis/error', label: '에러', visible: true },
                    { link: '/analysis/power', label: '전력', visible: true },
                    { link: '/analysis/idleTime', label: '기계 비 가동시간', visible: true },
                    { link: '/analysis/workTime', label: '작업시간', visible: true },
                ],
                visible: true
            },
            // {
            //     link: '/moldManagement', label: '금형 관리', links: [
            //         { link: '/mold/maintenance', label: '금형 타수 관리', visible: true },
            //         { link: '/mold/repairs', label: '금형 수리 요청 등록', visible: true },
            //         { link: '/mold/complete/admin', label: '금형 수리 완료 리스트(관리자)', visible: true },
            //         { link: '/mold/problem', label: '금형 문제 유형 등록', visible: true },
            //         { link: '/mold/MoldDailyRegister', label: '금형 일상점검 등록', visible: true },
            //         { link: '/mold/MoldDailyStatus', label: '금형 일상점검 일일현황', visible: true },
            //     ],
            //     visible: true
            // }
        ]
    },
    {
        label: "설비모니터링",
        visible: customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING,
        links: [
            {
                label: "설비모니터링", links: [
                    { link: "/overview", label: "MES 운영현황판", visible: true },
                    { link: "/monitoring", label: "현황 모니터링", visible: true },
                    { link: "/analysisMonitoring", label: "분석 모니터링", visible: true },
                ],
                visible: true
            },
            {
                label: '설비관리', links: [
                    { link: "/errorView", label: "에러보기", visible: true },
                    { link: "/maintenance", label: "설비 수리 요청", visible: true },
                    { link: "/maintenanceList", label: "설비 수리 요청 LIST", visible: true },
                    { link: "/maintenanceComplete", label: "설비 수리 완료 LIST", visible: true },
                    { link: "/equipmentProblem", label: "설비 문제 유형 등록", visible: true },
                    { link: "/dailyRegister", label: "설비 일상점검 등록", visible: true },
                    { link: "/dailyList", label: "설비 일상점검 현황", visible: true },
                ],
                visible: true
            },
            {
                label: "설비 통계분석", links: [
                    { link: "/output", label: "생산량", visible: true },
                    { link: "/error", label: "에러", visible: true },
                    { link: "/power", label: "전력", visible: true },
                    { link: "/idleTime", label: "기계 비가동 시간", visible: true },
                    { link: "/workTime", label: "작업시간", visible: true },
                ],
                visible: true
            }
        ]
    },
    {
        label: "SCM",
        visible: customFunctions.ADD_SCM_MENU_SETTING,
        links: [
            {
                label: "영업관리(고객사)", links: [
                    { link: "/purchaseOrder", label: "발주등록 (고객사)", visible: true },
                    { link: "/purchaseOrderItems", label: "발주항목 (고객사)", visible: true },
                    // { link: "/purchaseOrderItems-gyeongnam", label: "수주현황 (경남)", visible: true },
                    // { link: "/deliveryStatus-gyeongnam", label: "납품정보등록 (경남)", visible: true },
                    { link: "/deliveryStatus", label: "납품현황 (고객사)", visible: true },
                    // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
                ],
                visible: true
            },
            {
                label: "영업관리(협력사)", links: [
                    // { link: "/order-gyeongnam", label: "발주등록 (경남)", visible: true },
                    { link: "/order", label: "수주현황 (협력사)", visible: true },
                    { link: "/orderItems", label: "수주항목 (협력사)", visible: true },
                    // { link: "/deliveryStatus-supplier", label: "납품정보등록 (협력사)", visible: true },
                    // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
                    { link: "/deliveryStatus", label: "납품현황 (협력사)", visible: true },
                ],
                visible: true
            },
            {
                label: "입고관리(협력사)", links: [
                    { link: "/shipment-request_supplier", label: "입고의뢰", visible: true },
                    { link: "/receipt_supplier", label: "입고등록", visible: true },
                    { link: "/shipment-status_supplier", label: "입고현황", visible: true },
                    { link: "/label_supplier", label: "라벨발행", visible: true },
                ],
                visible: true
            }
        ],
    },
    {
        label: "GPS 시스템 메뉴",
        visible: customFunctions.ADD_GPS_SYSTEM_MENU_SETTING,
        links: [
            { link: "http://taekang-tracker.sizl.co.kr/", label: "차량 트래킹 시스템", visible: true, target: "_blank" },
        ]
    }
];

const userCode = getUserCodeByUser();

const RenderSubMenuItems = (items: (Link | NestedLink)[]) => {
    const { openModal } = useModal();

    const handleDownLoad = () => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('ko-KR');
        // 파일 다운로드 API
        axios
            .get(`${BASE_URL}/interport/export`, {
                responseType: "blob",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `기준정보_${formattedDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const handleUpload = () => {
        openModal(<FileImportForm />, null, "기준정보 업로드");
    };

    return items
        .filter((item) => !!item.visible)  // visible이 false인 경우 필터링
        .map((item) => {
            if ('links' in item) {
                return (
                    <Menu.Item component="div" key={item.label}>
                        <Flex justify="space-between" align="center">
                            <Menu shadow="md" trigger="hover" position="right" withArrow withinPortal>
                                <Menu.Target>
                                    <Flex justify="space-between" align="center" style={{ cursor: 'pointer', width: '100%' }}>
                                        <Text>{item.label}</Text>
                                        <IconChevronRight size="0.9rem" stroke={1.5} />
                                    </Flex>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {RenderSubMenuItems(item.links)}
                                </Menu.Dropdown>
                            </Menu>
                        </Flex>
                    </Menu.Item>
                );
            }
            return (
                <Menu.Item
                    key={item.label}
                    component={RouterLink}
                    to={item.action ? "#" : item.link}
                    target={item.target}
                    onClick={item.action ? (item.actionKey === 'download' ? handleDownLoad : item.actionKey === 'upload' ? handleUpload : undefined) : undefined}
                    color={item.action ? 'indigo' : ''}
                >
                    {item.label}
                </Menu.Item>
            );
        });
};

const renderMainMenuItems = (items: LinkType) => {
    return items
        .filter((item) => !!item.visible)  // visible이 false인 경우 필터링
        .map((item) => (
            <Menu key={item.label} shadow="md" trigger="hover" withArrow withinPortal>
                <Menu.Target>
                    {item.link ? (
                        <RouterLink to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Flex align="center" style={{ cursor: 'pointer' }}>
                                <Text>{item.label}</Text>
                            </Flex>
                        </RouterLink>
                    ) : (
                        <Flex align="center" style={{ cursor: 'pointer' }}>
                            <Text>{item.label}</Text>
                            {item.links && <IconChevronDown size="0.9rem" stroke={1.5} />}
                        </Flex>
                    )}
                </Menu.Target>
                {item.links && (
                    <Menu.Dropdown>
                        <DynamicHeightScrollArea>
                            {RenderSubMenuItems(item.links)}
                        </DynamicHeightScrollArea>
                    </Menu.Dropdown>
                )}
            </Menu>
        ));
};

const DynamicHeightScrollArea = ({ children }: { children: React.ReactNode }) => {
    const [ref, rect] = useResizeObserver(); // useResizeObserver로 ref와 rect 받아오기
    // 높이 설정: 내용이 MAX_HEIGHT보다 크면 MAX_HEIGHT, 아니면 rect.height 사용
    const calculatedHeight = rect.height > MAX_HEIGHT ? MAX_HEIGHT : rect.height;

    return (
        <ScrollArea h={calculatedHeight}>
            <Flex direction="column" ref={ref}>
                {children}
            </Flex>
        </ScrollArea>
    );
};

export const MenuTemplate = () => {

    const [filteredLinks, setFilteredLinks] = useState<LinkType>(links);

    useEffect(() => {
        if (userCode === 'FLOUR' || userCode === 'PB') {
            let filtered = links.filter(link => link.label === 'SCM');

            if (userCode === "FLOUR") {
                filtered = filtered.map(link => ({
                    ...link,
                    links: link.links?.filter(subLink => subLink.label === '영업관리(협력사)')
                }));
            }

            if (userCode === "PB") {
                filtered = filtered.map(link => ({
                    ...link,
                    links: link.links?.filter(subLink => subLink.label === '영업관리(고객사)')
                }));
            }

            setFilteredLinks(filtered);
        } else {
            setFilteredLinks(links);
        }
    }, [userCode]);

    return (
        <Flex gap="md">
            {renderMainMenuItems(filteredLinks)}
        </Flex>
    );
};