import { inventories, mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { lots } from "@/api/lots/useLotsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Select, SelectItem, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsTransferPostRequest, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner, WmsTransferPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: Select,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const TransferForm = (params: { formatterProps: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();

  const { mutate: transferMutate } = useMutation(
    (params: DefaultApiWmsTransferPostRequest) =>
      mutateInventories.transfer(params).mutationFn(params as DefaultApiWmsTransferPostRequest | any), {
    onSuccess: () => {
      queryClient.invalidateQueries(["inventories"]);
      closeModal(form.values);
      customAlert(
        "재고 이동에 성공하였습니다.",
        "재고 이동 성공",
        "green"
      )
    },
    onError: (error: any) => {
      customAlert(handleErrorResponse({ error, errorContext: "재고 이동" }), "재고 이동 실패", "red")
    }
  }
  ); //useDefectsMutation("create");
  const form = useForm<Partial<WmsTransferPostRequest>>({
    initialValues: {
      itemCode: undefined,
      sourceLocationCode: undefined,
      targetLocationCode: undefined,
      quantity: undefined,
      lotId: null,
    },
  });

  // const { data: lotData } = useGetLotsIdQuery(
  //   formatterProps && !isNaN(Number(formatterProps.lotId))
  //     ? Number(formatterProps.lotId)
  //     : form.values && !isNaN(Number(form.values?.lotId))
  //       ? Number(form.values?.lotId)
  //       : Number(),
  //   !!formatterProps.lotId || !!form.values?.lotId
  // );

  const { data: lotData } = useQuery({
    ...lots.detail({
      lotId: formatterProps && !isNaN(Number(formatterProps.lotId))
        ? Number(formatterProps.lotId)
        : form.values && !isNaN(Number(form.values?.lotId))
          ? Number(form.values?.lotId)
          : Number()
    }),
    enabled: !!formatterProps.lotId || !!form.values?.lotId
  })

  useEffect(() => {
    if (lotData?.data?.itemCode) {
      form.setValues({ itemCode: lotData?.data?.itemCode });
      form.setFieldValue("itemCode", lotData?.data?.itemCode);
    }
  }, [lotData, formatterProps, form.values?.lotId]);

  const query = lotData
    ? {
      $and: [
        { itemCode: { $eq: lotData?.data?.itemCode } },
        { lotId: { $eq: lotData?.data?.id } },
      ],
    }
    : {
      $and: [
        {
          itemCode: {
            $eq:
              (formatterProps && formatterProps.itemCode) ||
              form.values?.itemCode,
          },
        },
        {
          lotId: {
            $eq:
              (formatterProps && formatterProps.lotId) || form.values?.lotId,
          },
        },
      ],
    };

  const { data: inventory } = useQuery(inventories.get({
    query,
    populate: ["lot", "location", "item"],
  }));

  const outLocationData =
    lotData && inventory?.data?.rows
      ? inventory.data.rows
        .filter((row: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => row.locationCode !== undefined)
        .map((row: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
          return {
            value: row.locationCode,
            label: row.locationCode,
          };
        })
      : [];

  const onSubmit = () => {
    try {
      const { itemCode, sourceLocationCode, targetLocationCode, quantity } =
        form.values;
      if (!itemCode && !sourceLocationCode && !targetLocationCode && !quantity)
        return;
      transferMutate(
        {
          wmsTransferPostRequest: {
            itemCode: form.values.itemCode as string,
            sourceLocationCode:
              form.values.sourceLocationCode as string || formatterProps.locationCode as string,
            targetLocationCode: form.values.targetLocationCode as string,
            quantity: form.values.quantity ? form.values.quantity : formatterProps.quantity as string,
            lotId:
              formatterProps && !isNaN(Number(formatterProps.lotId))
                ? Number(formatterProps.lotId)
                : form.values && !isNaN(Number(form.values?.lotId))
                  ? Number(form.values?.lotId)
                  : Number(),
            actionFrom: 'WEB'
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: "재고 이동" }), "재고 이동 실패", "red")
    }
  };
  return (
    <>
      <Create.Wrapper>
        <Create.ItemAutoComplete
          disabled
          label={!customFunctions.ADD_VISIBLE_ITEM_CODE ? "품목코드" : "품목"}
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={
            formatterProps ? formatterProps.itemCode : lotData?.data?.itemCode
          }
        />
        <LotSelectComplete
          disabled
          label="LOT 정보"
          withAsterisk
          itemCodeValue={
            formatterProps.itemCode || (form.values.itemCode as string)
          }
          defaultValue={
            formatterProps
              ? formatterProps?.lotId?.toString()
              : form.values?.lotId?.toString()
          }
          {...form.getInputProps("lotId")}
        />
        <Create.Select
          disabled
          label="출고 로케이션코드"
          withAsterisk
          data={outLocationData as SelectItem[]}
          defaultValue={formatterProps ? formatterProps.locationCode : ""}
          {...form.getInputProps("sourceLocationCode")}
        />
        <LocationAutoComplete
          label="입고 로케이션코드"
          withAsterisk
          outCode={
            (formatterProps && formatterProps.locationCode) ||
            form.values.sourceLocationCode
          }
          {...form.getInputProps("targetLocationCode")}
        />
        <Create.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps ? formatterProps.quantity : ""}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button onClick={onSubmit}>재고 이동</Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
