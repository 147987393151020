import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Pagination } from "@mantine/core";
import { useEffect, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid from "react-data-grid";

interface Receipt {
    company: string;
    createdAt: string;
    serialKey: string;
}

interface ReceiptDetail {
    id: number;
    code: string;
    itemName: string;
    spec: string;
    location: string;
    lotNo: string;
    serialNumber: string;
    quantity: number;
    price: number;
    total: number;
    status: string;
    isCompleted: boolean;
}

interface ReceiptDetails {
    [key: string]: ReceiptDetail[];  // 각 공통 코드(CMM001, CMM002 등)가 key로 사용됨
}

interface ReceiptPageMockupDataType {
    receipts: Receipt[];  // 공통 코드 목록
    receiptDetails: ReceiptDetails;  // 각 공통 코드에 대한 세부 항목
}

const ReceiptWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
};

export const ShipmentReceiptPage = () => {
    const [serialKey, setSerialKey] = useState<string>("");  // 초기값을 빈 문자열로 설정
    const [details, setDetails] = useState<any[]>([]);  // 우측 detail 데이터를 저장하는 상태
    const [parentActivePage, setParentActivePage] = useState(1);  // 좌측 테이블의 페이지 상태
    const [childActivePage, setChildActivePage] = useState(1);  // 우측 테이블의 페이지 상태

    const pageSize = 10;

    // 선택된 공통 코드에 따른 필터링된 detail 데이터를 업데이트
    useEffect(() => {
        if (serialKey && ReceiptPageMockupData.receiptDetails[serialKey]) {
            setDetails(ReceiptPageMockupData.receiptDetails[serialKey]);
            setChildActivePage(1); // 새 코드 선택 시, 우측 페이지네이션 초기화
        } else {
            setDetails([]);  // code가 빈 문자열이면 details는 빈 배열로 설정
        }
    }, [serialKey]);

    // 좌측 데이터의 총 갯수 및 페이지 계산
    const totalParentRows = ReceiptPageMockupData.receipts.length;
    const parentPaginatedRows = ReceiptPageMockupData.receipts.slice(
        (parentActivePage - 1) * pageSize,
        parentActivePage * pageSize
    );

    // 우측 데이터의 총 갯수 및 페이지 계산
    const totalChildRows = details.length;
    const childPaginatedRows = details.slice(
        (childActivePage - 1) * pageSize,
        childActivePage * pageSize
    );

    const parentColumns: readonly Column<any>[] = [
        {
            key: "company",
            name: "거래처",
            sortable: true,
            resizable: true,
        },
        {
            key: "createdAt",
            name: "입고일",
            sortable: true,
            resizable: true,
        },
        {
            key: "serialKey",
            name: "식별자",
            sortable: true,
            resizable: true,
        }
    ];

    const childColumns: readonly Column<any>[] = [
        {
            key: "id",
            name: "입고번호",
            sortable: true,
            resizable: true,
        },
        {
            key: "code",
            name: "CODE",
            sortable: true,
            resizable: true,
        },
        {
            key: "itemName",
            name: "제품명",
            sortable: true,
            resizable: true,
        },
        {
            key: "spec",
            name: "규격",
            sortable: true,
            resizable: true,
        },
        {
            key: "location",
            name: "로케이션",
            sortable: true,
            resizable: true,
        },
        {
            key: "lotNo",
            name: "Lot 번호",
            sortable: true,
            resizable: true,
        },
        {
            key: "serialNumber",
            name: "Serial 번호",
            sortable: true,
            resizable: true,
        },
        {
            key: "quantity",
            name: "입고량",
            sortable: true,
            resizable: true,
        },
        {
            key: "price",
            name: "단가",
            sortable: true,
            resizable: true,
        },
        {
            key: "total",
            name: "총 금액",
            sortable: true,
            resizable: true,
        },
        {
            key: "status",
            name: "테스트 필요여부",
            sortable: true,
            resizable: true,
        },
        {
            key: "status",
            name: "상태",
            sortable: true,
            resizable: true,
        },
        {
            key: "isCompleted",
            name: "입고완료가능 여부",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => (
                <Badge color={row.isCompleted ? "red" : "green"}>
                    {row.isCompleted ? "불가능" : "가능"}
                </Badge>
            )
        }
    ];

    return (
        <ReceiptWrap.Wrapper>
            <ReceiptWrap.Header>
                <ReceiptWrap.HeaderTitle>입고 등록</ReceiptWrap.HeaderTitle>
                <ReceiptWrap.HeaderSubTitle>
                    {"입고 의뢰 건을 선택하고, 등록할 수 있습니다."}
                </ReceiptWrap.HeaderSubTitle>
            </ReceiptWrap.Header>
            <Flex w="100%" h="100%" gap="sm" direction="column">
                {/* 왼쪽 공통코드 테이블 */}
                <GridWrapper>
                    <DataGrid
                        columns={parentColumns ?? []}
                        rows={parentPaginatedRows ?? []}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.serialKey}
                        onRowClick={(row) => setSerialKey(row.serialKey)}  // 코드 선택 시 상태 업데이트
                    />
                    <Pagination
                        onChange={setParentActivePage}  // 페이지 변경 핸들러
                        value={parentActivePage}
                        total={Math.ceil(totalParentRows / pageSize)}  // 데이터에 따라 동적 계산
                        size="lg"
                        radius="sm"
                        position="center"
                    />
                </GridWrapper>

                {/* 우측 구분코드 테이블 (선택된 공통코드에 따라) */}
                <GridWrapper>
                    {serialKey ? (
                        <Flex direction="column" gap="sm" style={{ border: "1px solid black" }} p="md">
                            <Flex justify="flex-end">
                                {
                                    childPaginatedRows.filter(item => !item.isCompleted).length > 0 &&
                                    <Button>
                                        저장하기
                                    </Button>
                                }
                            </Flex>
                            <DataGrid
                                columns={childColumns ?? []}
                                rows={childPaginatedRows ?? []}  // 선택된 코드의 detail 데이터
                                rowHeight={40}
                                rowKeyGetter={(row) => row.categoryCode}
                            />
                            <Pagination
                                onChange={setChildActivePage}  // 우측 페이지네이션 핸들러
                                value={childActivePage}
                                total={Math.ceil(totalChildRows / pageSize)}  // 데이터에 따라 동적 계산
                                size="lg"
                                radius="sm"
                                position="center"
                            />
                        </Flex>
                    ) : (
                        <p>입고 의뢰 건을 선택하세요.</p>  // 코드가 선택되지 않은 경우
                    )}
                </GridWrapper>
            </Flex >
        </ReceiptWrap.Wrapper >
    );
}

// 목업 데이터 정의
const ReceiptPageMockupData: ReceiptPageMockupDataType = {
    receipts: [
        { company: "Company A", createdAt: "2024-10-01", serialKey: "A12345" },
        { company: "Company B", createdAt: "2024-10-02", serialKey: "B23456" },
        { company: "Company C", createdAt: "2024-10-03", serialKey: "C34567" }
    ],
    receiptDetails: {
        A12345: [
            {
                id: 1,
                code: "C001",
                itemName: "Product 1",
                spec: "100ml",
                location: "Warehouse 1",
                lotNo: "LOT001",
                serialNumber: "S001",
                quantity: 100,
                price: 10,
                total: 1000,
                status: "테스트 필요",
                isCompleted: true
            },
            {
                id: 2,
                code: "C002",
                itemName: "Product 2",
                spec: "200ml",
                location: "Warehouse 1",
                lotNo: "LOT002",
                serialNumber: "S002",
                quantity: 200,
                price: 15,
                total: 3000,
                status: "테스트 필요",
                isCompleted: true
            }
        ],
        B23456: [
            {
                id: 3,
                code: "C003",
                itemName: "Product 3",
                spec: "150ml",
                location: "Warehouse 2",
                lotNo: "LOT003",
                serialNumber: "S003",
                quantity: 150,
                price: 20,
                total: 3000,
                status: "테스트 필요 없음",
                isCompleted: false
            },
            {
                id: 4,
                code: "C004",
                itemName: "Product 4",
                spec: "250ml",
                location: "Warehouse 2",
                lotNo: "LOT004",
                serialNumber: "S004",
                quantity: 250,
                price: 18,
                total: 4500,
                status: "테스트 필요",
                isCompleted: false
            }
        ],
        C34567: [
            {
                id: 5,
                code: "C005",
                itemName: "Product 5",
                spec: "300ml",
                location: "Warehouse 3",
                lotNo: "LOT005",
                serialNumber: "S005",
                quantity: 300,
                price: 12,
                total: 3600,
                status: "테스트 필요 없음",
                isCompleted: true
            },
            {
                id: 6,
                code: "C006",
                itemName: "Product 6",
                spec: "500ml",
                location: "Warehouse 3",
                lotNo: "LOT006",
                serialNumber: "S006",
                quantity: 500,
                price: 25,
                total: 12500,
                status: "테스트 필요",
                isCompleted: true
            }
        ]
    }
};

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
