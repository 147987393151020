import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { ActionIcon, Flex, Title } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";

export const DateHeader = () => {
    const { openModal } = useModal();
    const { startDate, endDate, setStartDate, setEndDate, params, setParams } = useStandardLayout();

    return (
        <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
            <Title size="h1" weight={700}>
                {dayjs(startDate).format("YYYY-MM-DD")} ~ {dayjs(endDate).format("YYYY-MM-DD")}
            </Title>
            <ActionIcon onClick={() => openModal(
                <Calendar dates={[startDate, endDate]} type="range" />, null, "날짜 선택").then((result) => {
                    if (isCalendarDate(result)) {
                        const [start, end] = result;
                        setStartDate(start);
                        setEndDate(end);
                        // 함수형 업데이트 사용
                        setParams((prev) => ({
                            ...prev,
                            targetDateString: dayjs(start).toISOString(),
                            targetDateEndString: dayjs(end).toISOString(),
                        }));
                    }
                })
            }
            >
                <IconCalendar size="2rem" />
            </ActionIcon>
        </Flex>
    )
}