import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersCreateForm } from "@/features/users/components/form/UsersCreateForm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { UsersUpdateForm } from "@/features/users/components/form/UsersUpdateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { AuthSignupPost201Response, MasterApiUsersUserIdDeleteRequest, UsersGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, FormatterProps, SelectColumn } from "react-data-grid";

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export const Users = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiUsersUserIdDeleteRequest) =>
      mutateUsers.delete(params).mutationFn(params as MasterApiUsersUserIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("사용자 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "사용자 코드" }), "사용자 삭제 실패", "red");
      }
    }
  );

  const columns: readonly Column<AuthSignupPost201Response>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: "사용자 코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<UsersDetailForm UserCode={formatterProps.row.code} />, null, "")}>{formatterProps.row.code}</LinkAnchor>
        )
      },
    },
    {
      key: "name",
      name: "사용자명",
      sortable: true,
      resizable: true,
    },
    {
      key: "username",
      name: "로그인아이디",
      sortable: true,
      resizable: true,
    },
    {
      key: "equipmentCode",
      name: "설비코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<EquipmentDetailForm equipmentCode={formatterProps.row.equipmentCode} />, null, "")}>{formatterProps.row.equipmentCode}</LinkAnchor>
        )
      },
    },
    {
      key: "locationCode",
      name: "로케이션코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<LocationDetailForm locationCode={formatterProps.row.locationCode} />, null, "")}>{formatterProps.row.locationCode}</LinkAnchor>
        )
      },
    },
    ...(isDepartmentInformIncluded
      ? [
        {
          key: "departmentCode",
          name: "부서코드",
          sortable: true,
          resizable: true,
          formatter: (formatterProps: FormatterProps<AuthSignupPost201Response, unknown>) => {
            return <Flex>{formatterProps.row.departmentCode}</Flex>;
          },
        },
      ]
      : []),
    {
      key: "printerCode",
      name: "프린터 코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateUserActionView = () => {
          openModal(
            <UsersUpdateForm formatterProps={formatterProps?.row} />,
            null,
            "사용자 수정",
            true
          );
        }

        const deleteUserAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => { deleteMutate({ userId: formatterProps.row?.id as number }) }}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "사용자 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateUserActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteUserAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 사용자을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ userId: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "사용자 삭제",
    );
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<UsersCreateForm />, null, "사용자 추가", true)} createDataTitle="사용자" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>사용자 리스트</Text>
        <Text fz="xs">우리 회사의 사용자에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<UsersGet200Response, AuthSignupPost201Response> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<UsersGet200Response> />
      </Flex>
    </Flex>
  )
};
