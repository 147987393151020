import { peripheralHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { PeripheralCreateForm } from "@/features/peripheral/form/createForm";
import { PeripheralUpdateForm } from "@/features/peripheral/form/updateForm";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const PeripheralWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
    CreateForm: PeripheralCreateForm,
    UpdateForm: PeripheralUpdateForm,
};

export const PeripheralPage = () => {
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const { openModal } = useModal();
    const [activePage, setPage] = useState(initialQueryString.page);
    const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
    const [sort, setSort] = useState(initialQueryString.sort);
    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );

    const searchFieldsHeader = peripheralHeader.filter(
        (item) => item.category === "text" && !item.isEnum
    );

    const pageSize = 10;
    const totalRows = PeripheralPageMockupData.length;

    // 페이지에 맞는 데이터 슬라이싱
    const paginatedRows = PeripheralPageMockupData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
    );

    const columns: readonly Column<any>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
              & > * {
                justify-content: flex-start;
                padding-left: 24px;
              }
            `,
            cellClass: css`
              .rdg-checkbox-label {
                padding-left: 24px;
              }
            `,
        },
        {
            key: "manufacturedAt",
            name: "제조 연월일",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return <>{dayjs(formatterProps.row.manufacturedAt).format("YYYY-MM-DD")}</>
            }
        },
        {
            key: "manufactureCompany",
            name: "장치 제조사",
            sortable: true,
            resizable: true,
        },
        {
            key: "modelName",
            name: "장치명",
            sortable: true,
            resizable: true,
        },
        {
            key: "modelType",
            name: "장치 종류",
            sortable: true,
            resizable: true,
        },
        {
            key: "manufactureId",
            name: "제조번호",
            sortable: true,
            resizable: true,
        },
        {
            key: "userName",
            name: "담당자",
            sortable: true,
            resizable: true,
        },
        {
            key: "modelPicture",
            name: "장치 사진",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <img
                        src={formatterProps.row.modelPicture}
                        alt="modelPicture"
                        style={{ width: "100px", height: "100px" }}
                    />
                );
            }
        },
        {
            key: "specNamePlatePicture",
            name: "스펙 명판 사진",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <img
                        src={formatterProps.row.specNamePlatePicture}
                        alt="specNamePlatePicture"
                        style={{ width: "100px", height: "100px" }}
                    />
                );
            }
        },
        {
            key: "functionNamePlatePicture",
            name: "능력 명판 사진",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <img
                        src={formatterProps.row.functionNamePlatePicture}
                        alt="functionNamePlatePicture"
                        style={{ width: "100px", height: "100px" }}
                    />
                );
            }
        },
        {
            key: "manual",
            name: "사용 설명서",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <>
                        {formatterProps.row.manual}
                    </>
                );
            }
        },
        {
            key: "factoryName",
            name: "공장명",
            sortable: true,
            resizable: true,
        },
        {
            key: "factoryDetailName",
            name: "공장 세분화명",
            sortable: true,
            resizable: true,
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => {
                                openModal(
                                    <PeripheralWrap.UpdateForm
                                        formatterProps={formatterProps.row}
                                    />,
                                    {},
                                    "주변장치 수정",
                                    true
                                );
                            }}
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                window.confirm("정말로 삭제하시겠습니까?") &&
                                    customAlert(
                                        "주변장치 삭제에 실패하였습니다.",
                                        "삭제 실패",
                                        "red"
                                    );
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        }
    ];

    const deleteSelectedItems = () => {
        window.confirm("정말로 삭제하시겠습니까?") &&
            customAlert(
                "선택된 행 삭제에 실패하였습니다.",
                "삭제 실패",
                "red"
            );
    }

    return (
        <PeripheralWrap.Wrapper>
            <PeripheralWrap.ActionHeader>
                <PeripheralWrap.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() =>
                        openModal(<PeripheralWrap.CreateForm />, null, "주변장치 추가", true)
                    }
                >
                    추가
                </PeripheralWrap.ActionButton>
                <PeripheralWrap.ActionButton
                    color="red"
                    rightIcon={<IconTrash />}
                    onClick={() => deleteSelectedItems()}
                >
                    선택된 행 삭제
                </PeripheralWrap.ActionButton>
            </PeripheralWrap.ActionHeader>
            <PeripheralWrap.Header>
                <PeripheralWrap.HeaderTitle>주변장치 관리</PeripheralWrap.HeaderTitle>
                <PeripheralWrap.HeaderSubTitle>
                    {"우리 회사에서 다루는 주변장치에 대해 조회할 수 있는 페이지 입니다."}
                </PeripheralWrap.HeaderSubTitle>
            </PeripheralWrap.Header>
            <Flex w="100%" justify="space-between">
                <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                    <CustomFilter filterType={peripheralHeader} setQuery={setQuery} query={query} />
                    <CustomSorter sorterType={peripheralHeader} setSort={setSort} sort={sort} />
                </Flex>
                <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
            </Flex>
            <Flex w="100%" h="100%">
                <GridWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={paginatedRows}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.logDate}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                        }}
                    />
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={Math.ceil(totalRows / pageSize)}  // 전체 페이지 계산
                        size="lg"
                        radius="sm"
                        position="center"
                    />
                </GridWrapper>
            </Flex>
        </PeripheralWrap.Wrapper>
    )
}

const PeripheralPageMockupData = [
    {
        "manufacturedAt": dayjs("2024-10-14").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 1",
        "modelType": "하사점 검출장치",
        "manufactureId": 18354,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 1구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    },
    {
        "manufacturedAt": dayjs("2024-10-14").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 2",
        "modelType": "미스피드 검출장치",
        "manufactureId": 859,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 2구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    },
    {
        "manufacturedAt": dayjs("2024-10-14").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 3",
        "modelType": "하사점 검출장치",
        "manufactureId": 2873,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 3구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    },
    {
        "manufacturedAt": dayjs("2024-10-13").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 4",
        "modelType": "하사점 검출장치",
        "manufactureId": 338,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 4구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    },
    {
        "manufacturedAt": dayjs("2024-10-12").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 5",
        "modelType": "하사점 검출장치",
        "manufactureId": 853,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 1구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    },
    {
        "manufacturedAt": dayjs("2024-10-12").toDate(),
        "manufactureCompany": "삼성전자",
        "modelName": "AC-1200 6",
        "modelType": "미스피드 검출장치",
        "manufactureId": 297,
        "userName": "김철수",
        "factoryName": "평택사업장",
        "factoryDetailName": "2층 생산동 2구역",
        "modelPicture": null,
        "specNamePlatePicture": null,
        "functionNamePlatePicture": null,
        "manual": null
    }
]

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
