import { mutateStockPlans, stockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import customAlert from "@/features/ui/alert/alert";
import { TransportationsAutoComplete } from "@/features/ui/autoComplete/transportation/transportation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Table, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { StockPlansGet200ResponseRowsInner, WmsApiStockPlansStockPlanIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const Update = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface StockPlanUpdateFormProps {
    formatterProps: StockPlansGet200ResponseRowsInner
    stockPlanId: number[];
}

export const StockPlanTransportationForm = (params: StockPlanUpdateFormProps) => {
    const { formatterProps, stockPlanId } = params;
    const { closeModal } = useModal();


    const queryClient = useQueryClient();
    const { mutate: updateMutate } = useMutation(
        (params: WmsApiStockPlansStockPlanIdPutRequest) =>
            mutateStockPlans.update(params).mutationFn(params as WmsApiStockPlansStockPlanIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stockPlans']);
                customAlert(
                    "배차 등록에 성공하였습니다.",
                    "등록 성공",
                    "green"
                );
                closeModal(form.values);
            },
            onError: (error: any) => {
                customAlert(handleErrorResponse({ error, errorContext: '배차 등록' }), '배차 등록 수정 실패', 'red');
            }
        }
    );

    const stockPlanQueries = useQueries({
        queries: stockPlanId.map((id) => {
            const { queryKey, queryFn } = stockPlans.detail({ stockPlanId: id });
            return {
                queryKey,
                queryFn,
                // 필요 시 추가 옵션을 여기에 추가 가능합니다.
                // 예: enabled: !!id
            };
        })
    });

    const stockPlanData = stockPlanQueries.map(query => query.data); // 모든 데이터 배열로 모음

    const [transportationIds, setTransportationIds] = useState(
        stockPlanData.map(stockPlan => stockPlan?.data?.transportationId || "")
    );

    const handleTransportationChange = (index: number, value: string | any) => {
        setTransportationIds((prev) => {
            const newTransportationIds = [...prev];
            newTransportationIds[index] = value;
            return newTransportationIds;
        });
    };

    const form = useForm({
        initialValues: {
            partnerCode: undefined,
            scheduledAt: undefined,
            itemCode: undefined,
            lotId: undefined,
            quantity: undefined,
            userCode: undefined,
            sourceLocationCode: undefined,
            targetLocationCode: undefined,
            transportationId: undefined,
        }
    });

    const onSubmit = async () => {
        try {
            await Promise.all(
                stockPlanData.map((stockPlan, index) =>
                    updateMutate(
                        {
                            stockPlanId: stockPlan?.data?.id!,
                            stockPlansGetRequest: {
                                scheduledAt: stockPlan?.data?.scheduledAt!,
                                itemCode: stockPlan?.data?.itemCode!,
                                direction: stockPlan?.data?.direction!,
                                partnerCode: stockPlan?.data?.partnerCode!,
                                lotId: stockPlan?.data?.lotId!,
                                quantity: stockPlan?.data?.quantity!,
                                userCode: stockPlan?.data?.userCode!,
                                sourceLocationCode: stockPlan?.data?.sourceLocationCode!,
                                targetLocationCode: stockPlan?.data?.targetLocationCode!,
                                transportationId: Number(transportationIds[index]),
                            }
                        },
                    )
                )
            );
        } catch (error) {
            customAlert(handleErrorResponse({ error, errorContext: '배차 등록' }), '배차 등록 수정 실패', 'red');
        }
    };

    return (
        <>
            <Wrapper>
                {stockPlanData.map((stockPlan, index) => {
                    return (
                        <>
                            <Table withBorder withColumnBorders w="60rem" my="md">
                                <thead>
                                    <tr>
                                        <Th flex={1} className="small-column">순번</Th>
                                        <Th flex={2}>예정일시</Th>
                                        {
                                            !customFunctions.ADD_VISIBLE_ITEM_CODE && (
                                                <Th flex={2} className="big-column">품목코드</Th>
                                            )
                                        }
                                        <Th flex={2}>협력사</Th>
                                        <Th flex={2}>배차</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <Td flex={1} className="small-column">{index + 1}</Td>
                                        <Td flex={2}>{timeUtil(stockPlan?.data?.scheduledAt ?? '')}</Td>
                                        {
                                            !customFunctions.ADD_VISIBLE_ITEM_CODE && (
                                                <Td flex={2} className="big-column">{stockPlan?.data?.itemCode}</Td>
                                            )
                                        }
                                        <Td flex={2}>{stockPlan?.data?.partnerCode}</Td>
                                        <Td flex={2}>
                                            <TransportationsAutoComplete
                                                placeholder="배차 입력"
                                                value={transportationIds[index].toString()}
                                                onChange={(value) => handleTransportationChange(index, value)}
                                            />
                                        </Td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )
                })}
                <Update.ButtonBox>
                    <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                    <Update.Button
                        onClick={onSubmit}
                    >
                        배차 등록
                    </Update.Button>
                </Update.ButtonBox>
            </Wrapper>
        </>
    )
};

const Wrapper = styled.div`
    width: 100%;
`;

const Th = styled.th<{ flex: number }>`
    flex: ${({ flex }) => flex};
    text-align: center;
    min-width: 4rem;

    &.small-column {
        min-width: 2rem;
    }

    &.big-column {
        min-width: 8rem; 
    }
`;

const Td = styled.td<{ flex: number }>`
    flex: ${({ flex }) => flex};
    text-align: center;

    &.small-column {
        min-width: 2rem;
    }

    &.big-column {
        min-width: 8rem; /* 순번 열의 크기를 작게 설정 */
    }
`;
