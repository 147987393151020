import { customFunctions } from "@/config/customFunction";
import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import timeUtil from "@/utils/timeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPILeadTimeOrder = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPILeadTimeOrderPage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averageLeadTime, setAverageLeadTime] = useState<string>('');
    const { itemData, leadTimeOrderData } = useKPIData({ item, stateDate, options: { fetchLeadTimeOrder: true } })

    const TableHeader = {
        machine: [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목', '품명', '규격', '평균 수주/납품 리드타임(일)'],
    }

    useEffect(() => {
        if (leadTimeOrderData?.data) {
            const transformedData = leadTimeOrderData.data.map((leadTimeOrder: any) => ({
                purchaseOrderId: leadTimeOrder.purchaseOrderId,
                contractor: leadTimeOrder.contractor,
                orderDate: timeUtil(leadTimeOrder.orderDate),
                deadline: timeUtil(leadTimeOrder.deadline),
                firstDeliveryTime: timeUtil(leadTimeOrder.firstDeliveryTime),
                orderedQuantity: `${leadTimeOrder.orderedQuantity} ${itemData?.unitText}`,
                deliveredQuantity: `${leadTimeOrder.deliveredQuantity} ${itemData?.unitText}`,
                leadTime: leadTimeOrder.leadTime,
            }));

            const totalLeadTime = leadTimeOrderData?.data?.reduce((sum: number, leadTimeOrder: any) => sum + parseFloat(leadTimeOrder.leadTime), 0) ?? 0;
            const averageLeadTime = (totalLeadTime / leadTimeOrderData.data.length).toFixed(2);
            setAverageLeadTime(averageLeadTime);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [leadTimeOrderData])

    const BodyData = {
        machine: [{ [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목']: itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec, '평균 수주/납품 리드타임(일)': averageLeadTime === "NaN" ? '-' : averageLeadTime }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPILeadTimeOrder.Header>
                <KPILeadTimeOrder.HeaderTitle>수주/납품 리드타임(D)</KPILeadTimeOrder.HeaderTitle>
                <KPILeadTimeOrder.HeaderSubTitle>수주 생성일부터 최초 납품일까지 걸린 시간</KPILeadTimeOrder.HeaderSubTitle>
            </KPILeadTimeOrder.Header>
            <KPILeadTimeOrder.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />

            <KPILeadTimeOrder.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.leadTimeOrderTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}