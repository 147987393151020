import { mutateAbstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiAbstractItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface AbstractItemCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const AbstractItemCreateForm = (params: AbstractItemCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(
    (params: DefaultApiAbstractItemsPostRequest) =>
      mutateAbstractItems.create(params).mutationFn(params as DefaultApiAbstractItemsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['abstractItems']);
        closeModal(form.values);
        customAlert(
          "품목군 생성에 성공하였습니다.",
          "생성 성공",
          "green"
        )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '품목군코드' }), '품목군 생성 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          abstractItemsGetRequest: {
            code: code ?? form.values.code!,
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '품목군코드' }), '품목군 생성 실패', 'red')
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        label="품목군 코드"
        withAsterisk
        {...form.getInputProps("code")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          disabled={validateFormValues({
            pageType: "abstractItem",
            values: form.values,
          })}
        >
          품목군 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
