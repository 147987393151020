import { customFunctions } from "@/config/customFunction";
import { reducer, State } from "@/reducers/stateReducer";
import { useReducer } from "react";

const createInitialState = (): State => ({
    STOCK_SERIAL: true,
    INCOMING: true,
    OUTGOING: true,
    TRANSFER: true,
    TRANSFER_INPUT: true,
    BATCH: true,
    DILIGENCE: true,
    CLOSE: true,
    FLUSH: true,
    TRANSFORM: customFunctions.ADD_TRANSFORM_TYPE ? true : false,
});

const trackingStatus = (): State => ({
    WAITING: true,
    PAUSED: true,
    WORKING: true,
    DONE: true,
})

export const useWmsState = () => {
    const [state, dispatch] = useReducer(reducer, createInitialState());

    const toggle = (name: string) => dispatch({ type: 'TOGGLE', name });
    const setAll = (value: boolean) => dispatch({ type: 'SET_ALL', payload: value });

    return { state, toggle, setAll };
};

export const useWorkLogState = () => {
    const [state, dispatch] = useReducer(reducer, { "INPUT": true, "PRODUCTION": true });

    const toggle = (name: string) => dispatch({ type: 'TOGGLE', name });
    const setAll = (value: boolean) => dispatch({ type: 'SET_ALL', payload: value });

    return { state, toggle, setAll };
}

export const useOutsourceWorkbyRowState = () => {
    const [state, dispatch] = useReducer(reducer, trackingStatus());

    const toggle = (name: string) => dispatch({ type: 'TOGGLE', name });
    const setAll = (value: boolean) => dispatch({ type: 'SET_ALL', payload: value });

    return { state, toggle, setAll };
}

