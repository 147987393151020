import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiGradesFindPostRequest, MasterApiGradesGetRequest, MasterApiGradesGradeIdDeleteRequest, MasterApiGradesGradeIdPutRequest, MasterApiGradesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const grades = createQueryKeys('grades', {
    all: null,
    get: (params: MasterApiGradesGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // Grade 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => MasterInstance.gradesGet(queryParams),
        }
    },
    find: (params: MasterApiGradesFindPostRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // Grade 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => MasterInstance.gradesFindPost(queryParams),
        }
    },
});

export const mutateGrades = createMutationKeys('grades', {
    create: (params: MasterApiGradesPostRequest) => ({
        mutationKey: [params.gradesGetRequest],
        mutationFn: () => MasterInstance.gradesPost(params),
    }),
    update: (params: MasterApiGradesGradeIdPutRequest) => ({
        mutationKey: [params.gradeId, params.gradesGetRequest],
        mutationFn: () => MasterInstance.gradesGradeIdPut(params)
    }),
    delete: (params: MasterApiGradesGradeIdDeleteRequest) => ({
        mutationKey: [params.gradeId],
        mutationFn: () => MasterInstance.gradesGradeIdDelete(params)
    }),
});

export const itemCategoriesQueryKeys = mergeQueryKeys(grades, mutateGrades);