import { ColumnHeaderProps } from "@/constants/columnHeader";
import { StandardLayoutContextProvider } from "@/features/standardLayout/Context";
import { ReactNode } from "react";

interface BestPracticeQueryClientProviderProps {
    children: ReactNode;
    queryKeyFactory: any;
    searchFieldsHeader: ColumnHeaderProps[];
    populate: string[] | undefined;
    isUseParams?: boolean;
    isUseDate?: boolean;
}

export const StandardLayoutProvider = ({ children, queryKeyFactory, searchFieldsHeader, populate, isUseParams, isUseDate }: BestPracticeQueryClientProviderProps) => {
    return (
        <StandardLayoutContextProvider queryKeyFactory={queryKeyFactory} searchFieldsHeader={searchFieldsHeader} populate={populate} isUseParams={isUseParams} isUseDate={isUseDate}>
            {children}
        </StandardLayoutContextProvider>
    )
}