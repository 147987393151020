import { department, DEPARTMENT_QUERY_KEY, mutateDepartment } from "@/api/department/useDepartmentQuery";
import { departmentHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { DepartmentCreateForm } from "@/features/department/form/createForm";
import { DepartmentUpdateForm } from "@/features/department/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { DepartmentsGet200ResponseRowsInner, MasterApiDepartmentsDepartmentIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const DepartmentWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
    CreateForm: DepartmentCreateForm,
    UpdateForm: DepartmentUpdateForm,
};

export const DepartmentPage = () => {
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const { openModal } = useModal();
    const [activePage, setPage] = useState(initialQueryString.page);
    const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
    const [sort, setSort] = useState(initialQueryString.sort);
    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const queryClient = useQueryClient();

    const { mutate: deleteMutate } = useMutation(
        (params: MasterApiDepartmentsDepartmentIdDeleteRequest) =>
            mutateDepartment.delete(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([DEPARTMENT_QUERY_KEY]);
                customAlert(
                    "부서가 성공적으로 삭제되었습니다.",
                    "삭제 성공",
                    "green"
                )
            },
            onError: (error: AxiosError<Error>) => {
                customAlert(
                    error.response?.data?.message ?? "부서 삭제에 실패하였습니다.",
                    "삭제 실패",
                    "red"
                )
            }
        }
    );

    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );

    const searchFieldsHeader = departmentHeader.filter(
        (item) => item.category === "text" && !item.isEnum
    );

    const { data: departments } = useQuery(department.get({
        query: query,
        search: search.search,
        searchFields: search.searchFields.length
            ? search.searchFields
            : searchFieldsHeader.map((item) => item.value),
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: sort.length ? sort.join(",") : "-id"
    }));

    const rows = departments?.data.rows ?? [];
    const totalPage = departments?.data.totalPages ?? 10;



    const columns: readonly Column<DepartmentsGet200ResponseRowsInner>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
              & > * {
                justify-content: flex-start;
                padding-left: 24px;
              }
            `,
            cellClass: css`
              .rdg-checkbox-label {
                padding-left: 24px;
              }
            `,
        },
        {
            key: "code",
            name: "부서 코드 (필수)",
            sortable: true,
            resizable: true,
        },
        {
            key: "name",
            name: "부서명 (필수)",
            sortable: true,
            resizable: true,
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => {
                                openModal(
                                    <DepartmentWrap.UpdateForm
                                        {...formatterProps.row}
                                    />,
                                    {},
                                    "부서 수정",
                                    true
                                );
                            }}
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                window.confirm("정말로 삭제하시겠습니까?") &&
                                    deleteMutate({
                                        departmentId: formatterProps.row.id as number,
                                    })
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        }
    ];

    const deleteSelectedItems = () => {
        if (window.confirm("선택한 행을 삭제하시겠습니까?")) {
            const rows = Array.from(selectedRowsRef.current);
            if (!rows.length) return;
            const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
            deleteRows.forEach((row) => {
                deleteMutate({
                    departmentId: row,
                })
            });
            selectedRowsRef.current = new Set();
        }
    }

    return (
        <DepartmentWrap.Wrapper>
            <DepartmentWrap.ActionHeader>
                <DepartmentWrap.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() =>
                        openModal(<DepartmentWrap.CreateForm code={""} />, null, "부서 추가", true)
                    }
                >
                    추가
                </DepartmentWrap.ActionButton>
                <DepartmentWrap.ActionButton
                    color="red"
                    rightIcon={<IconTrash />}
                    onClick={() => deleteSelectedItems()}
                >
                    선택된 행 삭제
                </DepartmentWrap.ActionButton>
            </DepartmentWrap.ActionHeader>
            <DepartmentWrap.Header>
                <DepartmentWrap.HeaderTitle>부서 관리</DepartmentWrap.HeaderTitle>
                <DepartmentWrap.HeaderSubTitle>
                    {"우리 회사에서 다루는 부서에 대해 조회할 수 있는 페이지 입니다."}
                </DepartmentWrap.HeaderSubTitle>
            </DepartmentWrap.Header>
            <Flex w="100%" justify="space-between">
                <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                    <CustomFilter filterType={departmentHeader} setQuery={setQuery} query={query} />
                    <CustomSorter sorterType={departmentHeader} setSort={setSort} sort={sort} />
                </Flex>
                <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
            </Flex>
            <Flex w="100%" h="100%">
                <GridWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.id}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                        }}
                    />
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={totalPage}  // 전체 페이지 계산
                        size="lg"
                        radius="sm"
                        position="center"
                    />
                </GridWrapper>
            </Flex>
        </DepartmentWrap.Wrapper>
    )
}


const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: {(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: {(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
