import { mutateGrades } from "@/api/grade/useGradesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiGradesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface GradeCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export function GradeCreateForm(params: GradeCreateFormProps) {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiGradesPostRequest) =>
      mutateGrades.create(params).mutationFn(params as MasterApiGradesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['grades']);
        closeModal(form.values);
            customAlert(
              "등급 생성에 성공하였습니다.",
              "생성 성공",
              "green"
            )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '등급코드' }), '등급 생성 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: name ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          gradesGetRequest: {
            code: form.values.code!,
            name: name ?? form.values.name!,
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '등급코드' }), '등급 생성 실패', 'red')
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="등급코드"
            withAsterisk
            {...form.getInputProps("code")}
          />
          <TextInput
            label="등급명"
            withAsterisk
            width={"100%"}
            {...form.getInputProps("name")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "grade",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              등급 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  // & button {
  //     width: 54px;
  //     height: 40px;
  //     border-radius: 4px;

  //   &.cancel_btn {
  //     background-color: #ffffff;
  //   }
  // }
`;
