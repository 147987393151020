import { CavityForm } from "@/features/mold/cavity/form/cavityForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, NumberInput, Text, TextInput } from "@mantine/core";

import { mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldStocksMoldStockIdPutRequest, MoldStocksGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  CavityForm: CavityForm,
});

interface MoldStockUpdateFormProps {
  formatterProps: MoldStocksGet200ResponseRowsInner;
}

export const MoldStockUpdateForm = ({
  formatterProps,
}: MoldStockUpdateFormProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldStocksMoldStockIdPutRequest) =>
      mutateMoldStocks.update(params).mutationFn(params as MasterApiMoldStocksMoldStockIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['moldStock']);
        closeModal(form.values);
              customAlert(
                "금형재고 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '금형재고코드' }), '금형재고 수정 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      moldCode: formatterProps.moldCode,
      limitCounter: formatterProps.limitCounter ?? 0,
      inspectCounter: formatterProps.inspectCounter ?? 0,
      currentCounter: formatterProps.currentCounter ?? 0,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          moldStockId: formatterProps.id ?? 0,
          moldStocksGetRequest: {
            code: form.values.code!,
            moldCode: form.values.moldCode!,
            limitCounter: form.values.limitCounter!,
            inspectCounter: form.values.inspectCounter!,
            currentCounter: form.values.currentCounter!,
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '금형재고코드' }), '금형재고 수정 실패', 'red')
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        label="금형 재고 코드"
        withAsterisk
        {...form.getInputProps("code")}
        defaultValue={formatterProps.code}
        error={form.isDirty("code") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("code") ? "red !important" : "",
            borderColor: form.isDirty("code")
              ? "red !important"
              : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <MoldAutoComplete
        label="금형코드"
        withAsterisk
        {...form.getInputProps("moldCode")}
        defaultValue={formatterProps.moldCode}
        error={form.isDirty("moldCode") ? "저장 필요" : ""}
      />
      <Update.NumberInput
        label="최대 타수"
        {...form.getInputProps("limitCounter")}
        defaultValue={formatterProps.limitCounter}
        error={form.isDirty("limitCounter") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("limitCounter")
              ? "red !important"
              : "",
            borderColor: form.isDirty("limitCounter")
              ? "red !important"
              : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.NumberInput
        label="검사 타수"
        {...form.getInputProps("inspectCounter")}
        defaultValue={formatterProps.inspectCounter}
        error={form.isDirty("inspectCounter") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("inspectCounter")
              ? "red !important"
              : "",
            borderColor: form.isDirty("inspectCounter")
              ? "red !important"
              : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.NumberInput
        label="현재 타수"
        {...form.getInputProps("currentCounter")}
        defaultValue={formatterProps.currentCounter}
        error={form.isDirty("currentCounter") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("currentCounter")
              ? "red !important"
              : "",
            borderColor: form.isDirty("currentCounter")
              ? "red !important"
              : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          취소
        </Update.Button>
        <Update.Button
          disabled={validateFormValues({
            pageType: "moldStock",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          금형재고 수정
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
