import { mutateLocations } from "@/api/locations/useLocationsQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { LocationCreateForm } from "@/features/location/form/CreateForm";
import { LocationUpdateForm } from "@/features/location/form/UpdateForm";
import { ActionHeader } from "@/features/location/LocationActionHeader";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { LocationsGet200Response, LocationsGet200ResponseRowsInner, MasterApiLocationsLocationIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";


export const LocationPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiLocationsLocationIdDeleteRequest) =>
      mutateLocations.delete(params).mutationFn(params as MasterApiLocationsLocationIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("로케이션 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(handleErrorResponse({error, errorContext: '로케이션 코드'}), "로케이션 삭제 실패", "red");
      }
    }
  );

  const columns: readonly Column<LocationsGet200ResponseRowsInner>[] = useMemo(() => [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: "로케이션코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<LocationDetailForm locationCode={formatterProps?.row.code} />, null, "로케이션 상세")}>{formatterProps.row.code}</LinkAnchor>
        );
      },
    },
    {
      key: "name",
      name: "로케이션이름",
      sortable: true,
      resizable: true,
    },
    {
      key: "siteCode",
      name: "사업장코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<SiteDetailForm siteCode={formatterProps.row.siteCode} />, null, "")}>{formatterProps.row.siteCode}</LinkAnchor>
        )
      },
    },
    {
      key: "isAvailable",
      name: "가용 창고 여부",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (<Flex>{formatterProps.row.isAvailable ? "가용" : "비가용"}</Flex>)
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateLocationActionView = () => {
          openModal(
            <LocationUpdateForm formatterProps={formatterProps?.row} />,
            null,
            "로케이션 수정",
            true
          );
        };

        const deleteLocationAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => deleteMutate({ locationId: formatterProps?.row.id as number })}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "로케이션 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateLocationActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteLocationAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ], [deleteMutate, openModal, closeModal]);

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 로케이션을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ locationId: JSON.parse(row).id });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "로케이션 삭제",
    )
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<LocationCreateForm />, null, "로케이션 추가", true)} createDataTitle="로케이션" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>로케이션 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 로케이션에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<LocationsGet200Response, LocationsGet200ResponseRowsInner> columns={columns} rowKeyGetter={(row: LocationsGet200ResponseRowsInner) => JSON.stringify({ id: row.id, code: row.code })} />
        </StandardLayoutTableWrapper>
        <PaginationBox<LocationsGet200Response> />
      </Flex>
    </Flex>
  );
};
