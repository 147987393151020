import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { PurchaseOrdersGet200ResponseRowsInner, ScmApiPurchaseOrderItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface PurchaseOrderCreateFormProps {
    purchaseOrderId?: number;
    purchaseOrder?: PurchaseOrdersGet200ResponseRowsInner;
}

export const PurchaseOrderItemsCreateForm = (
    props: PurchaseOrderCreateFormProps
) => {
    const { purchaseOrderId, purchaseOrder } = props;
    const { closeModal } = useModal();
    const userCode = getUserCodeByUser();
    const [id, setId] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPostRequest) =>
            mutatePurchaseOrderItems.create(params).mutationFn(params as ScmApiPurchaseOrderItemsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['purchaseOrderItems']);
                closeModal(form.values);
                customAlert(
                    `${userCode === "PB" ? "발주 항목 등록에 성공하였습니다." : "수주 항목 등록에 성공하였습니다."}`,
                    "등록 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                customAlert(handleErrorResponse({ error, errorContext: `${userCode === 'PB' ? '발주 항목' : '수주 항목'}` }), `${userCode === "PB" ? '발주 항목 실패' : '수주 항목 실패'}`, 'red')
            }
        }
    );

    const { data: purchaseOrderItem } = useQuery({
        ...purchaseOrders.detail({
            purchaseOrderId: purchaseOrderId ?? Number(id) ?? 0,
            populate: ["location", "user", "partner", "purchaseOrderItems"],
        }),
        enabled: !!purchaseOrderId || !!id,
    })

    const getInitialValues = (
        purchaseOrder: PurchaseOrdersGet200ResponseRowsInner | undefined,
        purchaseOrderItem: AxiosResponse<PurchaseOrdersGet200ResponseRowsInner, any> | undefined
    ) => {
        return {
            purchaseOrderId: purchaseOrderId ? purchaseOrderId : purchaseOrder ? purchaseOrder.id : purchaseOrderItem ? purchaseOrderItem?.data?.id : undefined,
            partnerCode: purchaseOrderItem?.data?.partnerCode ?? purchaseOrder?.partnerCode ?? undefined,
            itemCode: undefined,
            quantity: undefined,
            orderDate: purchaseOrder?.orderDate ? new Date(purchaseOrder?.orderDate) : purchaseOrderItem ? new Date(purchaseOrderItem.data?.orderDate ?? '') : undefined,
            deliveryDeadline: purchaseOrder?.deliveryDeadline ? new Date(purchaseOrder?.deliveryDeadline) : purchaseOrderItem ? new Date(purchaseOrderItem?.data?.deliveryDeadline ?? '') : undefined,
            userCode: purchaseOrderItem?.data?.userCode ?? purchaseOrder?.userCode ?? undefined,
            sourceLocationCode: purchaseOrderItem?.data?.sourceLocationCode ?? purchaseOrder?.sourceLocationCode ?? undefined,
        }
    }

    const form = useForm({
        initialValues: getInitialValues(purchaseOrder, purchaseOrderItem),
    })

    useEffect(() => {
        if (purchaseOrderItem) {
            form.setValues(getInitialValues(purchaseOrder, purchaseOrderItem));
        }
    }, [purchaseOrderItem]);

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrderItemsGetRequest: {
                        purchaseOrderId: purchaseOrderId ? purchaseOrderId : purchaseOrder ? purchaseOrder.id : purchaseOrderItem ? purchaseOrderItem?.data?.id : form.values.purchaseOrderId!,
                        partnerCode: purchaseOrder?.partnerCode ?? form.values.partnerCode!,
                        direction: "INBOUND",
                        itemCode: form.values.itemCode!,
                        quantity: form.values.quantity!,
                        orderDate: purchaseOrder?.orderDate?.toString()! ?? form.values.orderDate!,
                        deliveryDeadline: purchaseOrder?.deliveryDeadline?.toString()! ?? form.values.deliveryDeadline!,
                        userCode: purchaseOrder?.userCode ?? form.values.userCode!,
                        sourceLocationCode: purchaseOrder?.sourceLocationCode ?? form.values.sourceLocationCode!,
                    }
                },
            )
        } catch (e) {
            customAlert(
                `${userCode === "PB" ? "발주 항목 등록에 실패하였습니다." : "수주 항목 등록에 실패하였습니다."}`,
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                {purchaseOrderId || purchaseOrder ? (
                    null
                ) : (
                    <PurchaseOrderAutoComplete
                        direction="INBOUND"
                        label={`${userCode === "PB" ? "발주 코드" : "수주 코드"}`}
                        placeholder={`${userCode === "PB" ? "발주 코드 입력" : "수주 코드 입력"}`}
                        {...form.getInputProps("purchaseOrderId")}
                        clearable
                        onChange={(e) => setId(e)}
                    />
                )}
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    defaultValue={purchaseOrder?.partnerCode}
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                />
                <ItemAutoComplete
                    label={!customFunctions.ADD_VISIBLE_ITEM_CODE ? "품목코드" : "품목"}
                    placeholder="품목 코드 입력"
                    {...form.getInputProps("itemCode")}
                />
                <Create.Input
                    label="수량"
                    placeholder="수량"
                    {...form.getInputProps("quantity")}
                />
                <UserAutoComplete
                    label="담당자"
                    placeholder="담당자 입력"
                    defaultValue={purchaseOrder?.userCode}
                    {...form.getInputProps("userCode")}
                />
                <LocationAutoComplete
                    label="출고 로케이션"
                    placeholder="출고 로케이션"
                    defaultValue={purchaseOrder?.sourceLocationCode}
                    {...form.getInputProps("sourceLocationCode")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="수주일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        {userCode === "PB" ? "발주 항목 생성" : "수주 항목 생성"}
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};