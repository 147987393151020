import { downtimeReasons, mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { ActionHeader } from "@/features/downtimeReasons/DownTimeReasonActionHeader";
import { DowntimeReasonsCreateForm } from "@/features/downtimeReasons/form/CreateForm";
import { DowntimeReasonsDetailForm } from "@/features/downtimeReasons/form/DetailForm";
import { DowntimeReasonsUpdateForm } from "@/features/downtimeReasons/form/UpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
// import DragAndDropList, { DragAndDropItemDataType } from "@/features/ui/dragAndDropList/DragAndDropList";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest, DowntimeReasonsGet200Response, DowntimeReasonsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const DowntimeReasonsPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { data: downtimeReasonsData } = useQuery(downtimeReasons.find({ query: { $and: [] }, sort: "seq" }));
  const dragAndDropItems = downtimeReasonsData?.data.map((item) => {
    return {
      id: item.code as string,
      content: `${item.name}( ${item.code} )`,
    }
  })

  const maxSeq = downtimeReasonsData?.data?.length
    ? downtimeReasonsData?.data?.[downtimeReasonsData?.data.length - 1]?.seq as number + 1
    : 0;

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest) =>
      mutateDownTimeReasons.delete(params).mutationFn(params as DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          "비가동사유 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "비가동사유 코드" }), "비가동사유 삭제 실패", "red");
      }
    }
  );

  const columns: readonly Column<DowntimeReasonsGet200ResponseRowsInner>[] = useMemo(() => [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "seq",
      name: "순서",
      sortable: true,
      resizable: true,
    },
    {
      key: "code",
      name: "비가동사유코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<DowntimeReasonsDetailForm downtimeReasonCode={formatterProps.row.code} />, null, "")}>{formatterProps.row.code}</LinkAnchor>
        )
      },
    },
    {
      key: "name",
      name: "비가동사유명",
      sortable: true,
      resizable: true,
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<OperationDetailForm operationCode={formatterProps.row.operationCode} />, null, "")}>{formatterProps.row.operationCode}</LinkAnchor>
        )
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateDownTimeReasonActionView = () => {
          openModal(
            <DowntimeReasonsUpdateForm
              formatterProps={formatterProps.row}
            />,
            {},
            "비가동사유 수정",
            true
          );
        }

        const deleteDownTimeReasonAction = () => {
          openModal(
            <ConfirmForm
              message="정말로 삭제하시겠습니까?"
              yesCallback={() =>
                deleteMutate(
                  {
                    downtimeReasonCode: formatterProps.row.code as string,
                  },
                )
              }
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "비가동사유 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateDownTimeReasonActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteDownTimeReasonAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ], [closeModal, deleteMutate, openModal]);


  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 비가동사유를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ downtimeReasonCode: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "비가동사유 삭제",
    )
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<DowntimeReasonsCreateForm maxSeq={maxSeq} />, null, "비가동사유 추가", true)} createDataTitle="비가동사유" deleteFn={() => handleDelete()} downTimeReasonItems={dragAndDropItems} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>비가동사유 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 비가동사유에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DowntimeReasonsGet200Response, DowntimeReasonsGet200ResponseRowsInner> columns={columns} rowKeyGetter={(row: DowntimeReasonsGet200ResponseRowsInner) => row.code} />
        </StandardLayoutTableWrapper>
        <PaginationBox<DowntimeReasonsGet200Response> />
      </Flex>
    </Flex>
  );
};
