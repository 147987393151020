import useGenerateDraftWorksQuery from "@/api/useGenerateDraftWorks";
import usePlansWithWorksMutation from "@/api/usePlansWithWorksMutations";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import styled from "@emotion/styled";
import { Button, Group, Stepper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ProductionPlansGenerateDraftWorksPost200ResponseChildren, ProductionPlansWithWorksPostRequest, ProductionPlansWithWorksPostRequestWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { ProductionPlanCreateInfoForm } from "./productionPlanCreateInfoForm";
import { ProductionPlanForm } from "./productionPlanForm";
import { WorkForm } from "./workForm";

interface ProductionPlanCreateFormProps {
    dateString?: string;
    formatterProps?: any;
    siteCode?: string;
    width?: string;
}

export type ExtractChildrenType = {
    itemCode: string;
    targetQuantity: string;
    works: (ProductionPlansWithWorksPostRequestWorksInner & { checked?: boolean, equipmentType?: string })[];

}

export const ProductionPlanCreateForm = (params: ProductionPlanCreateFormProps) => {
    const { dateString, formatterProps, siteCode } = params;
    const [active, setActive] = useState(0);
    const { closeModal } = useModal();
    const { mutate } = usePlansWithWorksMutation();


    const form = useForm<
        Partial<ProductionPlansWithWorksPostRequest> & {
            scheduledAtDate: Date;
            siteCode?: string;
            siteName?: string;
            works?: (ProductionPlansWithWorksPostRequestWorksInner & { checked?: boolean, equipmentType?: string })[];
            children?: ExtractChildrenType[];
        }
    >({
        initialValues: {
            itemCode: formatterProps?.itemCode ?? undefined,
            scheduledAtDate: dateString
                ? dayjs(dateString).startOf("D").toDate()
                : dayjs().startOf("D").toDate(),
            targetQuantity: undefined,
            works: [],
            sub: undefined,
            siteCode: siteCode,
            siteName: undefined,
            children: [
                {
                    itemCode: formatterProps?.itemCode ?? undefined,
                    targetQuantity: '',
                    works: [],
                },
            ],
            purchaseOrderItemId: formatterProps?.id?.toString() ?? undefined,
        },
    });

    const previousFormValuesRef = useRef(form.values);

    const queryDraftWorks = useGenerateDraftWorksQuery({
        itemCode: form.values.itemCode,
        targetQuantity: form.values.targetQuantity,
    });

    const { data: worksDraft } = queryDraftWorks;
    const isProductable = (worksDraft?.works || []).length !== 0;

    const prevStep = () => {
        form.setValues(previousFormValuesRef.current)
        setActive((current) => (current > 0 ? current - 1 : current));
    }

    const nextStep = async () => {

        const { data } = await queryDraftWorks.refetch();
        const extractChildren: ExtractChildrenType[] = [];

        const newSub = data?.sub?.result?.map((sub) => {
            return {
                itemCode: sub.itemCode,
                targetQuantity: sub.targetQuantity,
                works: sub.works.map((work) => {
                    return {
                        routingCode: work.routingCode,
                        targetQuantity: work.targetQuantity,
                        moldCode: data?.sub?.commonMoldCode,
                        routingOutsourceName: undefined,
                    };
                }),
                scheduledAt: form.values.scheduledAtDate.toISOString(),
                purchaseOrderItemId: form.values.purchaseOrderItemId
            };
        });


        form.setFieldValue("works", data?.works?.map((work) => ({ ...work, checked: true })));
        form.setFieldValue("sub", newSub);

        customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN
            && form.setFieldValue("children",
                extractDataRecursive(data?.children, extractChildren)
                    .map((child) => (
                        {
                            ...child,
                            works: child.works.map((work) => { return { ...work, checked: true } })
                        }
                    )));

        setActive((current) => current + 1);
    };

    // 1페이지 -> 2페이지 이동 조건
    const isValidStep1 = (form: any, isProductable: boolean) =>
        form.values.itemCode &&
        Number(form.values.targetQuantity) > 0 &&
        isProductable;

    const isValidStep2 = (form: any, active: number) =>
        active === 1 &&
        Array.isArray(form.values.works) &&
        form.values.works.every((element: any) => !!element.targetQuantity && element.equipmentCode) &&
        (!form.values.sub?.[0] || form.values.sub[0].works.every((element: any) => !!element.targetQuantity && element.equipmentCode));

    const isValidStep2WithChildren = (form: any, active: number) => {
        const selectedWorks = form.values.works.filter((work: any) => work.checked);

        const childrenWithSelectedWorks = form.values.children
            .map((child: any) => ({ ...child, ...child.works[0] }))
            .filter((child: any) => child.checked);

        if (active !== 1 || (!selectedWorks.length && !childrenWithSelectedWorks.length)) {
            return false;
        }

        return [...selectedWorks, ...childrenWithSelectedWorks].every(
            (item: any) => item.targetQuantity && item.equipmentCode
        );
    }

    const onSave = async () => {

        const { itemCode, scheduledAtDate, targetQuantity, works, purchaseOrderItemId } = form.values;

        // 패밀리 금형일 경우 sub 데이터 추가
        let additionalData;
        if (worksDraft?.sub && Object.keys(worksDraft?.sub).length) {
            additionalData = {
                commonMoldCode: worksDraft?.sub?.commonMoldCode,
                sub:
                    worksDraft?.sub &&
                    worksDraft.sub.result.map((row) => {
                        return {
                            itemCode: row.itemCode,
                            scheduledAt: scheduledAtDate.toISOString(),
                            targetQuantity: row.targetQuantity,
                            works: row.works,
                            purchaseOrderItemId: purchaseOrderItemId
                        };
                    }),
            };
        }

        await mutate(
            {
                purchaseOrderItemId: purchaseOrderItemId,
                itemCode: itemCode as string,
                scheduledAt: scheduledAtDate.toISOString(),
                targetQuantity: targetQuantity as string,
                works: works as ProductionPlansWithWorksPostRequestWorksInner[],
                ...additionalData,
            },
            {
                onSuccess: (res: any) => {
                    customAlert(
                        "생산계획 생성에 성공하였습니다.",
                        "생성 성공",
                        "green"
                    );
                    form.reset();
                    setActive(0);
                    closeModal(true);
                },
                onError: (error: any) => {
                    customAlert(
                        error.response?.data?.message ?? "생산계획 생성에 성공하였습니다.",
                        "생성 실패",
                        "red"
                    )
                }
            },

        );
    };

    const onSaveWithChildren = async () => {

        const { itemCode, scheduledAtDate, targetQuantity, works, purchaseOrderItemId, children } = form.values;

        const mergeInitDataWithChildren = [{ itemCode, targetQuantity, works }, ...children as ExtractChildrenType[]];

        for (const data of mergeInitDataWithChildren) {
            const { itemCode, targetQuantity, works } = data;
            mutate(
                {
                    purchaseOrderItemId: purchaseOrderItemId,
                    itemCode: itemCode as string,
                    scheduledAt: scheduledAtDate.toISOString(),
                    targetQuantity: targetQuantity as string,
                    works: works as ProductionPlansWithWorksPostRequestWorksInner[],
                },
                {
                    onSuccess: (res: any) => {
                        if (res.status === 200) {
                            customAlert(
                                "생산계획 생성에 성공하였습니다.",
                                "생성 성공",
                                "green"
                            );
                            form.reset();
                            setActive(0);
                            closeModal(true);
                        } else {
                            customAlert("생산계획 생성에 실패하였습니다.", "생성 실패", "red");
                        }
                    },
                    onError: (error: any) => {
                        customAlert(
                            error.response?.data?.message ?? "생산계획 생성에 실패하였습니다.",
                            "생성 실패",
                            "red"
                        )
                    }
                }
            );
        }
    }

    const setSiteName = (name: string) => {
        form.setFieldValue("siteName", name);
    };

    const setName = (i: number, name: string, j?: number) => {
        if (j !== undefined) return form.setFieldValue(`children.${i}.works.${j}.routingOutsourceName`, name);
        form.setFieldValue(`works.${i}.routingOutsourceName`, name);
    };

    const setSubName = (i: number, j: number, name: string) => {
        form.setFieldValue(`sub.${i}.works.${j}.routingOutsourceName`, name);
    };

    const extractDataRecursive = (data: ProductionPlansGenerateDraftWorksPost200ResponseChildren | undefined, result: ExtractChildrenType[] = []) => {
        // 현재 data가 유효한지 확인
        if (!data || Object.keys(data).length === 0) return result;

        // data의 첫 번째 result와 그 하위 children 탐색
        const current = data?.result?.[0];

        // 현재 depth의 데이터를 추출하여 배열에 추가
        result.push({
            itemCode: current?.itemCode as string,
            targetQuantity: current?.targetQuantity as string,
            works: current?.works as ProductionPlansWithWorksPostRequestWorksInner[]
        });

        // children이 빈 객체인지 확인 후, 빈 객체가 아니면 재귀 호출
        if (current?.children && Object.keys(current.children).length !== 0) {
            extractDataRecursive(current.children, result); // 재귀 호출
        }

        return result;
    }

    // 체크된만 추출하는 form 데이터 로직
    const extractCheckedData = (data: Partial<ProductionPlansWithWorksPostRequest> & {
        scheduledAtDate: Date;
        siteCode?: string;
        siteName?: string;
        children?: ExtractChildrenType[];
    }) => {
        previousFormValuesRef.current = form.values;

        const works = data?.works?.filter((work: any) => work?.checked);
        const children = data?.children?.map((child) => {
            return {
                ...child,
                works: child.works.filter((work) => work.checked)
            }
        });

        form.setFieldValue("works", works);
        form.setFieldValue("children", children);
    }

    const handleChecked = (checked: boolean) => {
        form.setFieldValue("works", form?.values?.works?.map((work) => ({ ...work, checked })));
        form.setFieldValue("children", form?.values?.children?.map((child) =>
            ({ ...child, works: child.works.map((work) => ({ ...work, checked })) })));
    }

    const isCheckedAll = () => {
        const isChildrenChecked = form?.values?.children?.every((child) => child.works.every((work) => work.checked));
        const isWorksChecked = form?.values?.works?.every((work: any) => work.checked) as boolean;
        return form?.values?.children?.length ? (isChildrenChecked && isWorksChecked) : isWorksChecked;
    };

    return (
        <FormWrapper>
            <form onSubmit={form.onSubmit((values) => console.log(values))}>
                <Stepper active={active} breakpoint="sm">
                    <Stepper.Step label="생산계획 정보 입력" description="생산계획 정보를 입력합니다.">
                        <ProductionPlanForm form={form} setSiteName={setSiteName} isProductable={isProductable} />
                    </Stepper.Step>
                    <Stepper.Step label="생성될 작업지시 확인" description="생성될 작업지시 확인 후 정보를 입력합니다.">
                        <WorkForm
                            checkedAll={isCheckedAll()}
                            form={form}
                            setName={setName}
                            setSubName={setSubName}
                            setCheckedAll={handleChecked}
                        />
                    </Stepper.Step>
                    <Stepper.Completed>
                        <ProductionPlanCreateInfoForm form={form} />
                    </Stepper.Completed>
                </Stepper>
                <Group position="right" mt="xl">
                    {active !== 0 && <Button variant="default" onClick={prevStep}>Back</Button>}
                    {active === 0 && <Button onClick={nextStep} disabled={!isValidStep1(form, isProductable)}>다음: 작업지시 만들기</Button>}
                    {active === 1 && <Button
                        onClick={() => {
                            customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && extractCheckedData(form.values);
                            setActive((current) => current + 1)
                        }} disabled={customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN
                            ? !isValidStep2WithChildren(form, active)
                            : !isValidStep2(form, active)}>
                        미리보기
                    </Button>}
                    {active === 2 && <Button onClick={
                        customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN
                            ? onSaveWithChildren
                            : onSave
                    }>생산계획 생성</Button>}
                </Group>
            </form>
        </FormWrapper>
    )
}

const FormWrapper = styled.div`
  width: 70rem;
`;
