import { customFunctions } from "@/config/customFunction";
import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPIManhour = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPIManhourPage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averageManHourCost, setAverageManHourCost] = useState<string>('');
    const [selectList, setSelectList] = useState<Set<number>>(new Set());

    const { itemData, manhourData } = useKPIData({ item, stateDate, options: { fetchManhour: true } })

    useEffect(() => {
        if (manhourData?.data) {
            const transformedData = manhourData.data.map((item: any) => ({
                id: item.id,
                productionPlanId: item.productionPlanId,
                scheduledAt: item.scheduledAt,
                operationName: item.operationName,
                equipmentName: item.equipmentName,
                itemCode: item.itemCode,
                itemSpec: item.itemSpec,
                workerName: item.workerName,
                workStartTime: DatetimeUtilMinute(item.workStartTime),
                workEndTime: DatetimeUtilMinute(item.workEndTime),
                pauseDuration: item.pauseDuration,
                totalQuantity: `${item.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${item.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${item.defectQuantity} ${itemData?.unitText}`,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                manHourCost: item.manHourCost,
            }));

            const totalManHourCost = manhourData?.data?.reduce((sum: number, item: any) => sum + parseFloat(item.manHourCost), 0) ?? 0;
            const averageManHourCost = (totalManHourCost / manhourData.data.length).toFixed(2);
            setAverageManHourCost(averageManHourCost);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [manhourData])

    const TableHeader = {
        machine: [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목', '품명', '규격', '평균 작업공수 (시간)'],
    }

    const BodyData = {
        machine: [{ [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목']: itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec ?? '-', '평균 작업공수 (시간)': averageManHourCost === "NaN" ? '-' : averageManHourCost }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPIManhour.Header>
                <KPIManhour.HeaderTitle>작업공수(C)</KPIManhour.HeaderTitle>
                <KPIManhour.HeaderSubTitle>작업지시에 대해 작업시작부터 작업종료까지 걸리는 시간 (단위: 시간)</KPIManhour.HeaderSubTitle>
            </KPIManhour.Header>
            <KPIManhour.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />

            <KPIManhour.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.manhourTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}