import { mutatePartners } from "@/api/partner/usePartnerQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiPartnersPartnerIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export const PartnerUpdateForm = (params: { formatterProps: any }) => {
    const { closeModal } = useModal();
    const { formatterProps } = params;
    const queryClient = useQueryClient();
    const { mutate: updateMutate } = useMutation(
        (params: MasterApiPartnersPartnerIdPutRequest) =>
            mutatePartners.update(params).mutationFn(params as MasterApiPartnersPartnerIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['partners']);
                closeModal(formatterProps);
                            customAlert(
                                "협력사 수정에 성공하였습니다.",
                                "수정 성공",
                                "green"
                            )
            },
            onError: (error) => {
                customAlert(handleErrorResponse({ error, errorContext: '협력사' }), '협력사 수정 실패', 'red')
            }
        }
    );

    const form = useForm({
        initialValues: {
            code: formatterProps.code,
            name: formatterProps.name,
            contact: formatterProps.contact,
            type: formatterProps.type,
        },
    });
    const onSubmit = async () => {
        try {
            updateMutate(
                {
                    partnerId: formatterProps.id,
                    partnersGetRequest: {
                        code: form.values.code!,
                        name: form.values.name!,
                        contact: form.values.contact!,
                        type: form.values.type!,
                    }
                },
            );
        } catch (error) {
            customAlert(handleErrorResponse({ error, errorContext: '협력사' }), '협력사 수정 실패', 'red')
        }
    };

    return (
        <Box w={320} mx="auto">
            <Form>
                <ModalFieldSet>
                    <TextInput
                        withAsterisk
                        label="협력사 코드"
                        placeholder="협력사 코드"
                        {...form.getInputProps("code")}
                        defaultValue={formatterProps.code}
                        error={form.isDirty("code") ? "저장 필요" : ""}
                        styles={{
                            input: {
                                color: form.isDirty("code")
                                    ? "red !important"
                                    : "",
                                borderColor: form.isDirty("code")
                                    ? "red !important"
                                    : "",
                            },
                            error: { fontSize: 11, color: "red" },
                        }}
                    />
                    <TextInput
                        withAsterisk
                        label="협력사 명"
                        placeholder="협력사 명"
                        {...form.getInputProps("name")}
                        defaultValue={formatterProps.name}
                        error={form.isDirty("name") ? "저장 필요" : ""}
                        styles={{
                            input: {
                                color: form.isDirty("name")
                                    ? "red !important"
                                    : "",
                                borderColor: form.isDirty("name")
                                    ? "red !important"
                                    : "",
                            },
                            error: { fontSize: 11, color: "red" },
                        }}
                    />
                    <TextInput
                        withAsterisk
                        label="연락처"
                        placeholder="연락처"
                        {...form.getInputProps("contact")}
                        defaultValue={formatterProps.contact}
                        error={form.isDirty("name") ? "저장 필요" : ""}
                        styles={{
                            input: {
                                color: form.isDirty("contact")
                                    ? "red !important"
                                    : "",
                                borderColor: form.isDirty("contact")
                                    ? "red !important"
                                    : "",
                            },
                            error: { fontSize: 11, color: "red" },
                        }}
                    />
                    <Select
                        withAsterisk
                        label="협력사 타입"
                        placeholder="협력사 타입"
                        {...form.getInputProps("type")}
                        defaultValue={formatterProps.type}
                        error={form.isDirty("type") ? "저장 필요" : ""}
                        data={
                            [
                                { value: "SALES", label: "판매" },
                                { value: "PURCHASE", label: "구매" },
                                { value: "BI_DIRECTION", label: "양방향" },
                            ]
                        }
                        styles={{
                            input: {
                                color: form.isDirty("type")
                                    ? "red !important"
                                    : "",
                                borderColor: form.isDirty("type")
                                    ? "red !important"
                                    : "",
                            },
                            error: { fontSize: 11, color: "red" },
                        }}
                    />
                    <ModalConfirmBtnBox>
                        <Button color="gray" onClick={closeModal}>
                            취소
                        </Button>
                        <Button
                            disabled={validateFormValues({
                                pageType: "partner",
                                values: form.values,
                            })}
                            onClick={onSubmit}
                        >
                            협력사 수정
                        </Button>
                    </ModalConfirmBtnBox>
                </ModalFieldSet>
            </Form>
        </Box>
    );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
