import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { usePostProductionPlanInputFromERPOutsourcingOrder } from "@/api/productionPlan/usePostProductionPlanGetQuery";
import { setERPOutsourcingSingleRecord } from "@/api/works/useWorkRecordQuery";
import { customFunctions } from "@/config/customFunction";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem, DefectTypeSet } from "@/features/ui/DefectTypeSet";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { OperationOutsourceNotFound } from "@/features/workByManagement/WorkNotFound";
import { WorkOperationTableHead } from "@/features/workByManagement/WorkOperationTableHead";
import { WorkOperationTableRow } from "@/features/workByManagement/WorkOperationTableRow";
import { WorkOperationTableRowInRow } from "@/features/workByManagement/WorkOperationTableRowInRow";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkProductionPlanTableHead } from "@/features/workByManagement/WorkProductionPlanTableHead";
import { WorkProductionPlanTableRow } from "@/features/workByManagement/WorkProductionPlanTableRow";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import { WorkUpdateForm } from "@/features/workByManagement/WorkUpdateForm";
import useWorkMutation from "@/features/workByManagement/api/useWorkMutation";
import { workPage } from "@/features/workByManagement/api/useWorkPageQuery";
import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import { SocketData } from "@/features/workByManagement/context/WorkProvider";
import useAutoResetState from "@/features/workByManagement/hooks/useAutoResetState";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import useLocalStorage from "@/hooks/useLocalStorage";
import { useOutsourceWorkbyRowState } from "@/hooks/useReducerState";
import { BASE_URL } from "@/instance/axios";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import { StatusObjType } from "@/utils/workStatus";
import styled from "@emotion/styled";
import { Button, Chip, Flex, Menu, Pagination, Select } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInner, DefaultApiWmsFlushPutRequest, ProductionPlansGet200ResponseRowsInnerWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum, WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar, IconSettings } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSocket } from "socket.io-react-hook";

const Work = {
    ActionBar: WorkActionBar,
    LeftActionBar: LeftActionBar,
    RightActionBar: RightActionBar,
    SiteSelect: WorkSiteSelect,
    Select: Select,
    Button: Button,
    WorkHeader: WorkHeader,
    WorkTableActionBar: WorkTableActionBar,
    WorkTableWrapper: WorkTableWrapper,
    WorkProductionPlanTable: WorkProductionPlanTable,
    WorkProductionPlanTableHead: WorkProductionPlanTableHead,
    WorkProductionPlanTableRow: WorkProductionPlanTableRow,
    WorkTable: WorkTable,
    WorkTableHead: WorkTableHead,
    WorkTableBody: WorkTableBody,
    WorkTableRow: WorkTableRow,
    WorkOperationTableHead: WorkOperationTableHead,
    WorkOperationTableRow: WorkOperationTableRow,
};

const useableOutsourceErpWorkOrder = customFunctions.ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER;
const useableOutsourceErpWorkRecord = customFunctions.ADD_ERP_WORK_RECORD_OUTSOURCING;

const trackingStatusObj: StatusObjType = {
    WAITING: '대기',
    PAUSED: '일시정지',
    WORKING: '진행중',
    DONE: '완료'
}

export const WorkByOperationOutsource = () => {

    const state = useWorkState();
    const actions = useWorkActions();
    const { resetAllChecked, setCheckedState, setWorkData } = useCheckboxContext();
    const checkArrangementRef = useRef(state.erpWorkData);
    const [activePage, setPage] = useState(1);
    const [equipmentCode, setEquipmentCode] = useState<string | null>(null);
    const [itemCode, setItemCode] = useState<string | null>(null);
    
    const { state: workRowState, toggle } = useOutsourceWorkbyRowState();
    const { openModal } = useModal();
    const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [[], []]);
    // useAutoResetState를 사용하여 상태를 관리
    const [updateSuccess, setUpdateSuccess] = useAutoResetState<boolean>(false, 50);
    const publish = usePub();
    const { updateQuantityMutate, updateDefectMutate, deleteMutate, putEquipmentCorrectionMutate } = useWorkMutation();
    const { loading, setLoading, LoadingOverlay } = useLoader();

    // const { mutate : flushMutate } = useInventoryMutation("flush");
    const { mutateAsync: inputERPOutsourcingWorkOrder } = usePostProductionPlanInputFromERPOutsourcingOrder();
    const queryClient = useQueryClient();
    const { mutate: flushMutate } = useMutation(
        (params: DefaultApiWmsFlushPutRequest) =>
            mutateInventories.flush(params).mutationFn(params as DefaultApiWmsFlushPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
                customAlert(
                    "재고가 초기화 되었습니다.",
                    "재고 초기화",
                    "info"
                )
            },
            onError: (error) => {
                customAlert(handleErrorResponse({ error, errorContext: "재고 초기화" }), "재고 초기화 실패", "red");
            }
        }
    )

    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });

    const [socketData, setSocketData] = useState<SocketData[]>([]);

    const { data, refetch } = useQuery(
        workPage.workItemForOutSource({
            defectCodes: (defectTypes && defectTypes[0]) ?? (defectTypes && defectTypes[1]),
            OSPDelvDate: dayjs(state.workDate[0]).format('YYYYMMDD'),
            query: {
                $and: [
                    {
                        // 최근 작업일 기준으로 변경 (2024-07-31 jswon)
                        lastWorkDate: {
                            $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
                        }
                        // scheduledAt: {
                        //     $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
                        // }
                    },
                    {
                        trackingStatus : Object.keys(workRowState).reduce((
                            result : ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum[], key : string
                        ) => {
                            if (workRowState[key as keyof typeof workRowState]) {
                                result.push(key as ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum);
                            }
                            return result;
                        }, [])
                    },
                    {
                        routingOutsourceId: { $ne: null }
                    },
                    ...(equipmentCode ? [{
                        equipmentCode: {
                            $eq: equipmentCode
                        }
                    }] : []),
                    ...(itemCode ? [{
                        itemCode: {
                            $eq: itemCode
                        }
                    }] : []),
                ]
            },
            page: activePage,
            sort: "-id",
        })
    );

    useEffect(() => {
        actions.setResetState();
    }, []);

    // 상태에 따라 rows 데이터를 업데이트 및 체크박스 초기화
    useEffect(() => {
        setWorkData(data?.rows);
        resetAllChecked();
    }, [data, workRowState]);

    useEffect(() => {
        data?.rows?.forEach((operationOutsource: any) => {
            if (Array.isArray(operationOutsource)) {
                operationOutsource.forEach((work: WorksItemGet200ResponseRowsInner) => {
                    const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
                    socket.on(roomName, (message: SocketData) => {
                        setSocketData((prev: SocketData[]) => {
                            const newData = [...prev, {
                                ...message,
                                workId: work.id
                            }];
                            return newData;
                        });
                    });
                    socket.emit(
                        "call",
                        "socket.join",
                        { room: roomName },
                        function (err: any, res: any) {
                            if (err) {
                                console.error(err);
                            }
                        }
                    );
                });
            }
        });

        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            data?.rows?.forEach((operationOutsource: any) => {
                if (Array.isArray(operationOutsource)) {
                    operationOutsource.forEach((work: WorksItemGet200ResponseRowsInner) => {
                        const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
                        socket.off(roomName);
                    });
                }
            });
        };

    }, [socket, data]);

    useEffect(() => {
        const planWorks = data?.rows?.map((plan) => plan).flat() as (WorksItemGet200ResponseRowsInner)[];
        if (planWorks && planWorks.length > 0) {
            actions.setTempWorks((prevWorks) => [...prevWorks, ...planWorks]);
        }
    }, [data]);


    const handleUpdateQuantity = async (work: WorksItemGet200ResponseRowsInner) => {
        const quantityData = state.tempQuantity.find(data => data.key === (work.id && work.id.toString()));
        if (work.id && quantityData?.value) {
            try {
                await updateQuantityMutate({
                    workId: work.id,
                    worksWorkIdPerformancePutRequest: {
                        accQuantity: quantityData.value
                    }
                }, {
                    onSuccess: () => {
                        actions.setTempQuantity([]);
                        setUpdateSuccess(true);  // 성공적인 API 호출 후 상태 업데이트
                    }
                });
            } catch (error) {
                alert("변경되지 않은 수량이 존재합니다. 다시 시도해주세요.");
            }
        }
    };

    const handleUpdateDefects = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner | AuthSignupPost201ResponseEquipmentWorksInner) => {
        const defectData = state.tempDefects.find(data => data.id === (work.id && work.id.toString()));
        // Update one defect at a time
        const updateDefectSequentially = async (defect: { key: string; value: string }) => {
            try {
                if (work.id) {
                    await updateDefectMutate({
                        workId: work.id,
                        worksWorkIdDefectPutRequest: {
                            defectCode: defect.key,
                            accQuantity: defect.value
                        }
                    });
                }
            } catch (e: any) {
                console.error("Error updating defect: ", e);
                throw e; // 에러를 다시 throw하여 밖에서 처리할 수 있도록 합니다.
            }
        };

        if (work.id && defectData) {
            for await (const defect of defectData.defect) {
                try {
                    // Await each update operation before continuing to the next one
                    await updateDefectSequentially(defect);
                } catch (e: any) {
                    if (e.response?.data?.message === "defectQuantity is greater than startQuantity") {
                        alert("불량 수량 저장에 실패했습니다. 불량 수량이 완료 수량을 초과하는지 다시 확인해주세요");
                        break; // 중단하고 나머지 업데이트를 취소합니다.
                    }
                    // Handle other unexpected errors
                    alert("예상치 못한 오류가 발생했습니다. 나중에 다시 시도 해주십시오.");
                    break; // Stop processing further updates in case of any error
                }
            }
            // Reset temporary defects and set update success state
            actions.setTempDefects([]);
            setUpdateSuccess(true);
        }
    };

    // 외주 생산계획 가져오기
    const getOutsourceProductionPlan = async () => {
        try {
            setLoading(true);
            const erpOutsourceWorkOrder = await inputERPOutsourcingWorkOrder();

            // 쿼리를 다시 불러오게 하기
            queryClient.invalidateQueries(['workPage']);

            const { insert: workInsert, update: workUpdate, delete: workDelete } = erpOutsourceWorkOrder.data ?? { insert: [], update: [], delete: [] };
            const insertCount = (Array.isArray(workInsert) ? workInsert.length : 0);
            const updateCount = (Array.isArray(workUpdate) ? workUpdate.length : 0);
            const deleteCount = (Array.isArray(workDelete) ? workDelete.length : 0);

            const alertParts = [
                insertCount ? insertCount + "건 연동" : "",
                updateCount ? updateCount + "건 갱신" : "",
                deleteCount ? deleteCount + "건 삭제" : ""
            ];

            const alertString = `외주 생산계획 ${alertParts.filter(part => part).join(", ")}되었습니다.`;
            customAlert(alertString, '외주 생산계획 연동', 'info');

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    // 외주실적 전달하기
    useEffect(() => {
        if (
            state.erpWorkData?.OSPDelvDate?.length &&
            state.erpWorkData?.workIds?.length &&
            state.erpWorkData.OSPDelvDate.length !== state.erpWorkData.workIds.length
        ) {
            // OSPDelvDate의 마지막 요소를 제거
            state.erpWorkData.OSPDelvDate.pop();
        }
        checkArrangementRef.current = state.erpWorkData;
    }, [state.erpWorkData, checkArrangementRef]);

    const postERPOutsourcing = async () => {
        if (state.erpWorkData?.OSPDelvDate?.length &&
            state.erpWorkData?.workIds?.length &&
            state.erpWorkData.OSPDelvDate.length === state.erpWorkData.workIds.length) {
            setLoading(true);

            try {

                const result = await setERPOutsourcingSingleRecord({
                    workIds: checkArrangementRef.current?.workIds,
                    startWorkLogIds: checkArrangementRef.current?.startWorkLogId,
                    endWorkLogIds: checkArrangementRef.current?.endWorkLogId,
                    OSPDelvDates: checkArrangementRef.current?.OSPDelvDate,
                });
                const { data } = result;
                if (data) {
                    const response = data ?? [];
                    let lotName = response.map((res: any) => res).join(', ');

                    customAlert(`로트명: ${lotName}이 연동되었습니다.`, '', 'info');

                    state.erpWorkData.OSPDelvDate = [];
                    state.erpWorkData.workIds = [];
                    state.erpWorkData.startWorkLogId = [];
                    state.erpWorkData.endWorkLogId = [];

                    publish('unCheckPoint');
                    publish('unCheck');

                    queryClient.invalidateQueries(['workPage']);

                }
            } catch (e) {
                console.error('외주실적 전달 중 오류 발생:', error);
                customAlert('외주실적 전달 중 오류가 발생했습니다.', '실적 전달', 'red');
            } finally {
                setLoading(false);
            }
        }
    }

    // rowsData와 각 rowData의 항목에 초기 상태 설정
    useEffect(() => {
        data?.rows?.forEach((rowData, rowIndex) => {
            if (rowData.allLotsSummary?.length === 0) return;
            rowData?.allLotsSummary?.forEach((lot, checkboxIndex) => {
                // 이미 ERP 연동된 데이터는 어차피 checkbox를 누르지 못하므로 초기값을 설정할 필요가 없음
                if (!!lot.erpSync) return
                setCheckedState(rowData.id, lot, rowIndex, checkboxIndex, false); // 초기값을 false로 설정
            });
        });
    }, [data?.rows]);

    return (
        <WorkExampleContainer>
            <LoadingOverlay />
            <Work.ActionBar>
                <Work.LeftActionBar>
                    <Flex gap="sm" direction={'column'}>
                        {/* TODO: 현재 선택된 사업장 => 추가 필요 */}
                        {/* <Work.Select data={[]} placeholder="사업장 선택" /> */}
                        <Flex gap="sm">
                            <Work.SiteSelect />
                            {/* TODO: 현재 선택된 라인 => 추후 테이블 추가 필요하고 equipment에 lineId 추가 필요  */}
                            <Work.Select data={[]} placeholder="라인 선택" />
                            <Work.Button
                                leftIcon={<IconCalendar />}
                                onClick={() => {
                                    openModal(<Calendar dates={state.date} type="range" />, null, "날짜 선택", true)
                                        .then((value) => {
                                            if (isCalendarDate(value)) actions.setDate(value)
                                            resetAllChecked()
                                        })
                                }
                                }
                            >날짜선택</Work.Button>
                        </Flex>
                        <Flex gap="sm">
                            <EquipmentAutoComplete
                                onChange={(value) => { setEquipmentCode(value) }}
                                placeholder="설비를 선택해주세요"
                            />
                            <ItemAutoComplete
                                onChange={(value) => { setItemCode(value) }}
                                placeholder="품목을 선택해주세요"
                                itemType="PRODUCT,SEMI_PRODUCT"
                            />
                        </Flex>
                    </Flex>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Menu shadow="md" width={150} position="bottom-end">
                        <Menu.Target>
                            <Button color="gray" rightIcon={<IconSettings />}>
                                Settings
                            </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => {
                                openModal(<DefectTypeSet />, null, "불량 유형 설정").then((result) => {
                                    // result 데이터가 특정 데이터일 경우 불량 타입 재조회 (불량 유형 설정을 완료했다는 의미)
                                    if (Array.isArray(result)) {
                                        setDefectTypes(result as DefectTypeItem[][])
                                    }
                                })
                            }}>
                                Settings
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Work.RightActionBar>
            </Work.ActionBar>
            <Work.WorkHeader dateType="range" />
            <Work.WorkTableActionBar>
                <Work.LeftActionBar>
                    <Work.Button onClick={() => openModal(
                        <ProductionPlanCreateForm />,
                        {},
                        "생산계획 생성",
                        true
                    ).then(() => refetch())}>생산계획 생성</Work.Button>
                    <Work.Button variant="light" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        openModal(
                            <WorkInputForm<'plan'>
                                workRowData={work as WorksItemGet200ResponseRowsInner}
                            />,
                            null,
                            "원/부자재 투입",
                            true
                        );
                    }}>원/부자재 투입</Work.Button>
                    <Work.Button variant="light" color="orange" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        flushMutate({
                            wmsFlushPutRequest: {
                                locationCode: ((work as WorksItemGet200ResponseRowsInner)?.targetLocationCode ?? '') || ((work as WorksItemGet200ResponseRowsInner)?.equipment?.targetLocationCode ?? '') || ((work as WorksItemGet200ResponseRowsInner)?.routingData?.operation?.toLocationCode ?? '')
                            }
                        }, )
                    }}>재고 초기화</Work.Button>
                    {
                        useableOutsourceErpWorkOrder &&
                        <Work.Button
                            variant="outline"
                            color="violet"
                            disabled={loading}
                            onClick={getOutsourceProductionPlan}
                        >{loading ? '외주 생산계획 가져오는 중' : '외주 생산계획 가져오기'}</Work.Button>
                    }
                    {
                        useableOutsourceErpWorkRecord &&
                        <Work.Button
                            variant="outline"
                            color="teal"
                            disabled={loading}
                            onClick={postERPOutsourcing}
                        >{loading ? '외주 실적 전달하는 중' : '외주 실적 전달하기'}</Work.Button>
                    }
                    <Work.Button variant="light" color="teal" disabled={!((state.works) && state.works.length === 1)} onClick={async () => {
                        const [work] = state.works as (WorksItemGet200ResponseRowsInner)[];;
                        if (work.id && work.equipmentCode && work.trackingStatus === "WORKING") {
                            const result = await putEquipmentCorrectionMutate({
                                equipmentsCorrectionPutRequest: {
                                    // equipmentCode: work.equipmentCode
                                    workId: work.id
                                }
                            });
                            result.data.length ? customAlert(
                                "재고 보정이 완료되었습니다.",
                                "재고 보정",
                                "info"
                            ) : customAlert(
                                "보정할 재고가 존재하지 않습니다.",
                                "재고 보정",
                                "red"
                            )
                        } else {
                            customAlert(
                                "작업이 진행 중인 설비에 대해서만 재고 보정이 가능합니다.",
                                "재고 보정",
                                "red"
                            )
                        }
                    }}>재고 보정</Work.Button>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Work.Button
                        leftIcon={<IconCalendar />}
                        onClick={() => {
                            openModal(<Calendar dates={state.workDate} type="default" />, null, "날짜 선택", true)
                                .then((value) => {
                                    if (isCalendarDate(value)) {
                                        actions.setWorkDate(value)
                                        resetAllChecked()
                                    }
                                })
                        }}
                    >작업날짜 선택</Work.Button>
                    <Work.Button color="yellow"
                        disabled={!((state.works) && state.works.length === 1)}
                        onClick={() => {
                            const [work] = state.works;
                            openModal(
                                <WorkUpdateForm<'plan'>
                                    workRowData={work as WorksItemGet200ResponseRowsInner[]}
                                />,
                                null,
                                "작업 수정",
                                true
                            )
                        }}>수정</Work.Button>
                    <Work.Button color="red" disabled={!((state.works) && state.works.length >= 1)} onClick={() => {
                        if (window.confirm("선택한 작업을 삭제하시겠습니까?")) {
                            state.works.forEach((work) => {
                                work.id && deleteMutate({ workId: work.id });
                            })
                        }
                    }}>삭제</Work.Button>
                    {/* <Work.Button onClick={() => {
                        // doSomething
                    }}>모두 저장</Work.Button> */}
                    <Work.Button disabled={!((state.works) && state.works.length >= 1)}
                        onClick={() => {
                            // const [work] = state.works;
                            state.works.forEach(async (work) => {
                                await handleUpdateQuantity(work);
                                await handleUpdateDefects(work);
                            });
                            // await handleUpdateQuantity(work);
                            // await handleUpdateDefects(work);
                        }}>저장</Work.Button>
                </Work.RightActionBar>
            </Work.WorkTableActionBar>
            <Work.WorkTableActionBar>
                <Work.LeftActionBar></Work.LeftActionBar>
                <Work.RightActionBar>
                    <ChipsBox>
                        {
                            Object.keys(trackingStatusObj).map((key) => (
                                <Chip
                                    variant="filled"
                                    size="md"
                                    radius="sm"
                                    key={key}
                                    checked={workRowState[key] || false}
                                    onClick={() => toggle(key)}
                                >
                                    {trackingStatusObj[key]}
                                </Chip>
                            ))
                        }
                    </ChipsBox>
                </Work.RightActionBar>
            </Work.WorkTableActionBar>
            <Work.WorkTableWrapper>
                <Work.WorkProductionPlanTable>
                    <Work.WorkOperationTableHead defectTypes={(defectTypes && defectTypes[0]) ?? (defectTypes && defectTypes[1])} />
                    <tbody>
                        {
                            data && data?.rows?.length ? data?.rows?.map((plan: WorksItemGet200ResponseRowsInner, idx) => {
                                // 소켓 데이터를 해당 ID에 맞게 필터링
                                const socketDataObject = socketData.find((data) => data.workId === plan.id);
                                return (
                                    <Work.WorkOperationTableRow socketData={socketDataObject} rowData={{ ...plan, purchaseOrderItem: plan.purchaseOrderItem }} key={plan.id || idx} defectTypes={(defectTypes && defectTypes[0]) ?? (defectTypes && defectTypes[1])} formReset={updateSuccess}>
                                        <tr>
                                            <Td colSpan={9} style={{ padding: "0" }}>
                                                <Work.WorkTable isWithBorder={false}>
                                                    <Work.WorkTableBody>
                                                        {/* WorkRow 영역 */}
                                                        {plan && Array(plan)?.map((work: WorksItemGet200ResponseRowsInner, index: number) => {
                                                            return (
                                                                <WorkOperationTableRowInRow rowData={work} rowIndex={idx} />
                                                            );
                                                        })
                                                        }
                                                    </Work.WorkTableBody>
                                                </Work.WorkTable>
                                            </Td>
                                        </tr>
                                    </Work.WorkOperationTableRow>
                                )
                            }) : <OperationOutsourceNotFound />
                        }
                    </tbody>
                </Work.WorkProductionPlanTable>
            </Work.WorkTableWrapper>
            <Pagination
                onChange={(page) => {
                    resetAllChecked()
                    setPage(page)
                }}
                value={activePage}
                total={data?.totalPages as number}
                size="lg"
                radius="sm"
            />
        </WorkExampleContainer >
    )
}

const WorkExampleContainer = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center; // flex-start;
    gap: 8px;
`

const Td = styled.td<{ minWidth?: number; width?: number; isNumber?: boolean }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

const StatusChip = styled(Chip)`
  label {
    min-width : 2rem;
    min-height : 2rem;
    height : 2.4rem;
    font-size : 1rem;
    color : gray;
  }
`