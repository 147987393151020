import { grades } from "@/api/grade/useGradesQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { GradeDetailHeader } from "../header/GradeDetailHeader";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface DetailGradeFormProps {
    gradeCode: string | undefined;
}

export const GradeDetailForm = (params: DetailGradeFormProps) => {
    const { gradeCode } = params;

    const { data: gradeData } = useQuery(grades.get({
        query: { $and: [{ "code": { "$eq": gradeCode } }] },
    }));

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <GradeDetailHeader data={gradeData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
};