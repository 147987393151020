import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiEquipmentsEquipmentIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const EquipmentUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { formatterProps } = params;

  const { mutate: updateMutate } = useMutation(
    (params: MasterApiEquipmentsEquipmentIdPutRequest) =>
      mutateEquipment.update(params).mutationFn(params as MasterApiEquipmentsEquipmentIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['equipment']);
        closeModal(formatterProps);
        customAlert(
          "설비 수정에 성공하였습니다.",
          "수정 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: '설비 코드' }), '설비 수정 실패', 'red')
      }
    }
  )

  const form = useForm({
    initialValues: {
      siteCode: formatterProps.siteCode,
      code: formatterProps.code,
      name: formatterProps.name,
      equipmentType: formatterProps.equipmentType,
      volt: formatterProps.volt,
      seq: formatterProps.seq,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
      targetLocationCode: formatterProps.targetLocationCode
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          equipmentId: formatterProps?.id,
          equipmentsEquipmentIdDeleteRequest: {
            siteCode: form.values.siteCode,
            code: form.values.code,
            name: form.values.name,
            equipmentType: form.values.equipmentType,
            volt: form.values.volt,
            seq: form.values.seq,
            fromLocationCode: form.values.fromLocationCode,
            toLocationCode: form.values.toLocationCode,
            targetLocationCode: form.values.targetLocationCode
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '설비 코드' }), '설비 수정 실패', 'red')
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            width={"100%"}
            {...form.getInputProps("siteCode")}
            defaultValue={formatterProps?.siteCode}
            error={form.isDirty("siteCode") ? "저장 필요" : ""}
          />
          <TextInput
            withAsterisk
            label="설비코드"
            {...form.getInputProps("code")}
            defaultValue={formatterProps?.code}
            disabled
          />
          <TextInput
            withAsterisk
            label="설비명"
            {...form.getInputProps("name")}
            defaultValue={formatterProps?.name}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name")
                  ? "red !important"
                  : "",
                borderColor: form.isDirty("name")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label="설비유형"
            {...form.getInputProps("equipmentType")}
            defaultValue={formatterProps?.equipmentType}
            error={form.isDirty("equipmentType") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("equipmentType")
                  ? "red !important"
                  : "",
                borderColor: form.isDirty("equipmentType")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <NumberInput
            label="설비전압"
            {...form.getInputProps("volt")}
            value={!formatterProps.volt ? 0 : formatterProps.volt}
            error={form.isDirty("volt") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("volt") ? "red !important" : "",
                borderColor: form.isDirty("volt")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />

          <LocationAutoComplete
            label="원부자재 창고"
            defaultValue={formatterProps?.toLocationCode}
            error={form.isDirty("") ? "저장 필요" : ""}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            label="입고 창고"
            defaultValue={formatterProps?.toLocationCode}
            error={form.isDirty("toLocationCode") ? "저장 필요" : ""}
            {...form.getInputProps("toLocationCode")}
          />
          <LocationAutoComplete
            label="품목 생산 창고"
            defaultValue={formatterProps?.targetLocationCode}
            error={form.isDirty("targetLocationCode") ? "저장 필요" : ""}
            {...form.getInputProps("targetLocationCode")}
          />
          <TextInput
            type="number"
            label="시퀀스(순서)"
            {...form.getInputProps("seq")}
            defaultValue={formatterProps?.seq}
            error={form.isDirty("seq") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("seq") ? "red !important" : "",
                borderColor: form.isDirty("seq")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "equipment",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              설비 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
