import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsDiligencePostRequest, WmsDiligencePostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Diligence = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const DiligenceForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: diligenceMutate } = useMutation(
    (params: DefaultApiWmsDiligencePostRequest) =>
      mutateInventories.diligence(params).mutationFn(params as DefaultApiWmsDiligencePostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
              customAlert(
                "재고 조정에 성공하였습니다.",
                "재고 조정 성공",
                "green"
              )
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "재고 조정" }), "재고 조정 실패", "red"
        )
      }
    }
  )

  const form = useForm<Partial<WmsDiligencePostRequest>>({
    initialValues: {
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotId: null,
      lotInfo: {
        name: null,
        expiration: null,
      },
    },
  });

  const onSubmit = () => {
    try {
      const { itemCode, locationCode, quantity } = form.values;
      if (!itemCode && !locationCode && !quantity) return;

      diligenceMutate(
        {
          wmsDiligencePostRequest: {
            itemCode:
              formatterProps.itemCode || (form.values.itemCode as string),
            locationCode:
              formatterProps.locationCode ||
              (form.values.locationCode as string),
            quantity: form.values.quantity as string,
            lotId: Number(formatterProps.lotId || form.values.lotId),
            lotInfo: {
              name:
                formatterProps.lot.name ||
                (form.values.lotInfo && form.values.lotInfo.name
                  ? form.values.lotInfo.name
                  : null),
              expiration:
                formatterProps.lot.expiration ||
                (form.values.lotInfo && form.values.lotInfo.expiration
                  ? form.values.lotInfo.expiration.toString()
                  : null),
            },
            actionFrom: 'WEB'
          },
        },
      );
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "재고 조정" }), "재고 조정 실패", "red"
      )
    }
  };

  return (
    <>
      <Diligence.Wrapper>
        <Diligence.ItemAutoComplete
          disabled
          label={!customFunctions.ADD_VISIBLE_ITEM_CODE ? "품목코드" : "품목"}
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <LocationAutoComplete
          disabled
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
          defaultValue={formatterProps?.locationCode}
        />
        <Diligence.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <Diligence.Input
          disabled
          label="LOT ID"
          {...form.getInputProps("lotId")}
          defaultValue={formatterProps?.lotId}
        />
        <Diligence.Input
          disabled
          label="로트명"
          {...form.getInputProps("lotInfo.name")}
          defaultValue={formatterProps?.lot?.name}
        />
        <Diligence.Date
          disabled
          label="만료일"
          //   placeholder="만료일을 선택하세요."
          minDate={new Date()}
          valueFormat="YYYY-MM-DD HH:mm:ss"
          {...form.getInputProps("lotInfo.expiration")}
          defaultValue={formatterProps?.lot?.expiration}
        />
        <Diligence.ButtonBox>
          <Diligence.Button color="gray" onClick={closeModal}>
            취소
          </Diligence.Button>
          <Diligence.Button onClick={onSubmit}>재고 조정</Diligence.Button>
        </Diligence.ButtonBox>
      </Diligence.Wrapper>
    </>
  );
};
