import { customFunctions } from "@/config/customFunction";
import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPILeadTime = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPILeadtimePage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any[]>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [item, setItem] = useState<string | null>('');
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [averageLeadTime, setAverageLeadTime] = useState<string>('');

    const { itemData, leadTimeData } = useKPIData({ item, stateDate, options: { fetchLeadTime: true } })


    useEffect(() => {
        if (leadTimeData?.data) {
            const transformedData = leadTimeData.data.map((leadTime: any) => ({
                productionPlanId: leadTime.productionPlanId,
                scheduledAt: timeUtil(leadTime.scheduledAt),
                itemCode: leadTime.itemCode,
                itemSpec: leadTime.itemSpec,
                workerName: leadTime.workerName,
                workEndTime: DatetimeUtilMinute(leadTime.workEndTime),
                workStartTime: DatetimeUtilMinute(leadTime.workStartTime),
                leadTime: leadTime.leadTime,
            }));

            // leadTime의 평균 계산
            const totalLeadTime = leadTimeData?.data?.reduce((sum: number, leadTime: any) => sum + parseFloat(leadTime.leadTime), 0) ?? 0;
            const averageLeadTime = (totalLeadTime / leadTimeData.data.length).toFixed(2);
            setAverageLeadTime(averageLeadTime);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [leadTimeData]);


    const TableHeader = {
        machine: [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목', '품명', '규격', '평균 제조리드타임 (시간)'],
    }

    const BodyData = {
        machine: [{ [!customFunctions.ADD_VISIBLE_ITEM_CODE ? '품목코드' : '품목']: itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec ?? '-', '평균 제조리드타임 (시간)': averageLeadTime === "NaN" ? '-' : averageLeadTime }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPILeadTime.Header>
                <KPILeadTime.HeaderTitle>제조리드타임(P)</KPILeadTime.HeaderTitle>
                <KPILeadTime.HeaderSubTitle>생산계획에 대해 작업시작부터 작업종료까지 걸리는 시간 (단위: 시간)</KPILeadTime.HeaderSubTitle>
            </KPILeadTime.Header>
            <KPILeadTime.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />
            <KPILeadTime.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.leadTimeItemTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                maxWidth={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}