import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWipInventoriesGetRequest, DefaultApiWipInventoriesPostRequest, DefaultApiWipInventoriesWipInventoryIdDeleteRequest, DefaultApiWipInventoriesWipInventoryIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const wipInventory = createQueryKeys('wipInventory', {
    all: null,
    get: (params: DefaultApiWipInventoriesGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 재공품 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => DefaultInstance.wipInventoriesGet(queryParams),
        }
    },
    // get : (params: DefaultApiWipInventoriesGetRequest) => {
    //     const query = { ...params };
    //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey : [query, JSON.stringify(query.query)],
    //         queryFn : () => DefaultInstance.wipInventoriesGet(query)
    //     }
    // },
});

export const mutateWipInventory = createMutationKeys('wipInventory', {
    create: (params: DefaultApiWipInventoriesPostRequest) => ({
        mutationKey: [params.wipInventoriesGetRequest],
        mutationFn: () => DefaultInstance.wipInventoriesPost(params),
    }),
    update: (params: DefaultApiWipInventoriesWipInventoryIdPutRequest) => ({
        mutationKey: [params.wipInventoryId, params.wipInventoriesGetRequest],
        mutationFn: () => DefaultInstance.wipInventoriesWipInventoryIdPut(params)
    }),
    delete: (params: DefaultApiWipInventoriesWipInventoryIdDeleteRequest) => ({
        mutationKey: [params.wipInventoryId],
        mutationFn: () => DefaultInstance.wipInventoriesWipInventoryIdDelete(params)
    }),
});