import { items } from "@/api/items/useItemQuery";
import { KPI } from "@/api/kpi/useKPIQuery";
import { getEndOfDate, getStartOfDate } from "@/utils/dateTimeUtil";
import { useQuery } from "@tanstack/react-query";

interface KPIDataProps {
    item?: string | null;
    equipment?: string | null;
    stateDate: [Date | null, Date | null];
    options: {
        fetchDefectRate?: boolean;
        fetchLeadTime?: boolean;
        fetchLeadTimeOrder?: boolean;
        fetchManhour?: boolean;
        fetchPowerUsage?: boolean;
        fetchUPH?: boolean;
        fetchRateOfOperation?: boolean;
    }
}
export const useKPIData = ({
    item,
    equipment,
    stateDate,
    options = {}
}: KPIDataProps) => {
    const {
        fetchDefectRate = false,
        fetchLeadTime = false,
        fetchLeadTimeOrder = false,
        fetchManhour = false,
        fetchPowerUsage = false,
        fetchUPH = false,
        fetchRateOfOperation = false,
    } = options;

    const { data } = useQuery({
        ...items.get({
            query: {
                $and: [
                    { code: item }
                ]
            }
        }),
        enabled: !!item
    });

    const itemData = data?.data?.rows && data?.data?.rows[0]

    const { data : equipments } = useQuery({
        ...items.get({
            query: {
                $and: [
                    { code: equipment }
                ]
            }
        }),
        enabled: !!equipment
    })

    const equipmentData = equipments?.data?.rows && equipments?.data?.rows[0]

    // 품질불량률
    const { data: defectData } = useQuery({
        ...KPI.defectRate({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item && fetchDefectRate
    });

    // 제조 리드타임
    const { data: leadTimeData } = useQuery({
        ...KPI.leadTime({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item && fetchLeadTime
    })

    // 수주/납품 리드타임
    const { data: leadTimeOrderData } = useQuery({
        ...KPI.leadTimeOrder({
            itemCode: item ?? '',
            scheduledAtFrom: stateDate[0]?.toISOString() ?? '',
            scheduledAtTo: stateDate[1]?.toISOString() ?? ''
        }),
        enabled: !!item && fetchLeadTimeOrder
    });

    // 작업공수
    const { data: manhourData } = useQuery({
        ...KPI.manhour({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item && fetchManhour
    });
    // 전력사용량
    const { data: powerUsageData } = useQuery({
        ...KPI.powerUsage({
            itemCode: item ?? '',
            scheduledAtFrom: stateDate[0]?.toISOString() ?? '',
            scheduledAtTo: stateDate[1]?.toISOString() ?? ''
        }),
        enabled: !!item && fetchPowerUsage
    });
    // UPH
    const { data: UPHData } = useQuery({
        ...KPI.UPH({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item && fetchUPH
    });

    // 설비가동률
    const { data: rateOfOperationData } = useQuery({
        ...KPI.rateOfOperation({
            equipmentCode: equipment ?? '',
            scheduledAtFrom: stateDate[0]?.toISOString() ?? '',
            scheduledAtTo: stateDate[1]?.toISOString() ?? ''
        }),
        enabled: !!equipment && fetchRateOfOperation
    });

    return { itemData, equipmentData, defectData, leadTimeData, leadTimeOrderData, manhourData, powerUsageData, UPHData, rateOfOperationData }
}